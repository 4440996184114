import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import { get, isEmpty } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { faStar as faEmptyStar } from "@fortawesome/free-regular-svg-icons";
import { compressToEncodedURIComponent } from "lz-string";
import Table from "../../components/Table";
import Loader from "../../components/Loader";
import ListCard from "../../components/ListCard";
import * as widgetActions from "../../actions/widgets";
import * as locationActions from "../../actions/locations";
import * as customTagActions from "../../actions/customTags";
import * as reportActions from "../../actions/reports";
import styled from "styled-components";
import { WidgetGraphType, WidgetValueType } from "./Widget/types";
import { getCompanyId } from "../../helpers";
import WidgetGrid from "./ContentViews/widgetGrid";
import Locations from "./ContentViews/locations";
import HomeSideBar from "./LayoutControllers/homeSideBar";
import HomeHeader from "./LayoutControllers/homeHeader";

// const WPAColors = {
//   COLLABORATION_ROOMS: "#1C8082",
//   SUPPORTING_ROOMS: "#8f2d56",
//   ACTIVITY_ZONE: "#624444",
//   PEOPLE_COUNT: "#1c4d82",
//   WORKSTATIONS: "#542344"
// };

const WPAColors = {
  OVERVIEW: "#111",
  COLLABORATION_ROOMS: "#17786b",
  SUPPORTING_ROOMS: "#9C315E",
  ACTIVITY_ZONE: "#1A8793",
  PEOPLE_COUNT: "#14628F",
  WORKSTATIONS: "#613286"
};

const WPAColorsDark = {
  OVERVIEW: "#111",
  COLLABORATION_ROOMS: "#344b47",
  SUPPORTING_ROOMS: "#67213E",
  ACTIVITY_ZONE: "#156F79",
  PEOPLE_COUNT: "#0F496B",
  WORKSTATIONS: "#41215A"
};

const getWidgets = (locationName, widgetProfile, page) => {
  console.log("getWidgets", locationName, widgetProfile, page);

  const startDate = moment.utc().subtract(1, "month").startOf("month");
  const endDate = moment.utc().subtract(1, "month").endOf("month");
  const lastMonth = moment.utc().subtract(1, "month").startOf("month");
  const lastLastMonth = moment.utc().subtract(2, "month").startOf("month");
  const lastLastLastMonth = moment.utc().subtract(3, "month").startOf("month");
  const lastLastLastLastMonth = moment.utc().subtract(4, "month").startOf("month");
  const lastLastLastLastLastMonth = moment.utc().subtract(5, "month").startOf("month");

  const monthString = lastMonth.format("MMMM");
  const monthYearString = lastMonth.format("MMMM YYYY");
  const lastMonthString = lastLastMonth.format("MMMM");
  const lastLastMonthString = lastLastLastMonth.format("MMMM");
  const lastLastLastMonthString = lastLastLastLastMonth.format("MMMM");
  const lastLastLastLastMonthString = lastLastLastLastLastMonth.format("MMMM");
  const monthDailyCount = endDate.diff(startDate, "days") + 1;

  // Make array from 1 to monthDailyCount
  const monthDailyArray = Array.from({ length: monthDailyCount }, (v, k) => k + 1);

  // Get the color based on the category name
  let mainColor = "#333";
  let darkColor = "#999";
  let resourceName = "rooms";
  let singleResourceName = "room";
  if (page.id === null) {
    mainColor = WPAColors.PEOPLE_COUNT;
    darkColor = WPAColorsDark.PEOPLE_COUNT;
    resourceName = "locations";
    singleResourceName = "location";
  }
  else if (page.name === "CollaborationSpace" || page.name === "Meeting Rooms") {
    mainColor = WPAColors.COLLABORATION_ROOMS;
    darkColor = WPAColorsDark.COLLABORATION_ROOMS;
  }
  else if (page.name === "SupportingSpace" || page.name === "Quiet Rooms") {
    mainColor = WPAColors.SUPPORTING_ROOMS;
    darkColor = WPAColorsDark.SUPPORTING_ROOMS;
  }
  else if (page.name === "ActivityZone") {
    mainColor = WPAColors.ACTIVITY_ZONE;
    darkColor = WPAColorsDark.ACTIVITY_ZONE;
    resourceName = "zones";
    singleResourceName = "zone";
  }
  else if (page.name === "Workstations") {
    mainColor = WPAColors.WORKSTATIONS;
    darkColor = WPAColorsDark.WORKSTATIONS;
    resourceName = "workstations";
    singleResourceName = "workstation";
  }
  else if (page.id === "overview") {
    mainColor = WPAColors.OVERVIEW;
    darkColor = WPAColorsDark.OVERVIEW;
  }

  // When in Overview - return predefined widgets
  if (page.id === "overview") {
    return get(widgetProfile, "overview.widgets", []);
  }
  else if (page.id === null) {
    const customTagId = get(widgetProfile, "peopleCount.customTagId", undefined);
    return [
      {
        id: "peopleCountIntraday",
        customTagId: customTagId,
        wpaCategoryNames: ["People count"],
        type: WidgetGraphType.VerticalBar,
        valueType: WidgetValueType.Count,
        dateKey: "hour",
        title: `Daily people count`,
        subtitle: lastMonth.format("MMMM YYYY"),
        description: `The highest number of people by hour of day in ${locationName}.`,
        dataLayout: [
          { property: "peakPeak", hour: 7, color: mainColor, description: "07-08" },
          { property: "peakPeak", hour: 8, color: mainColor, description: "" },
          { property: "peakPeak", hour: 9, color: mainColor, description: "" },
          { property: "peakPeak", hour: 10, color: mainColor, description: "" },
          { property: "peakPeak", hour: 11, color: mainColor, description: "" },
          { property: "peakPeak", hour: 12, color: mainColor, description: "" },
          { property: "peakPeak", hour: 13, color: mainColor, description: "" },
          { property: "peakPeak", hour: 14, color: mainColor, description: "" },
          { property: "peakPeak", hour: 15, color: mainColor, description: "" },
          { property: "peakPeak", hour: 16, color: mainColor, description: "16-17" }
        ]
      },
      {
        id: "peopleCountWeekday",
        customTagId: customTagId,
        type: WidgetGraphType.VerticalBar,
        valueType: WidgetValueType.Count,
        dateKey: "weekday",
        title: `Weekly people count`,
        subtitle: lastMonth.format("MMMM YYYY"),
        description: `The highest number of people in by day of the week in ${locationName}.`,
        showMaxLine: true,
        dataLayout: [
          { property: "peakPeak", weekday: 1, color: mainColor, description: "Monday" },
          { property: "peakPeak", weekday: 2, color: mainColor, description: "" },
          { property: "peakPeak", weekday: 3, color: mainColor, description: "" },
          { property: "peakPeak", weekday: 4, color: mainColor, description: "" },
          { property: "peakPeak", weekday: 5, color: mainColor, description: "Friday" },
        ]
      },
      {
        id: "peopleCountMonth",
        customTagId: customTagId,
        type: WidgetGraphType.HorizontalBar,
        valueType: WidgetValueType.Count,
        title: `Monthly people count`,
        subtitle: lastMonth.format("MMMM YYYY"),
        description: `The highest number of people in ${locationName}.`,
        showMaxLine: true,
        dataLayout: [
          { property: "peakPeak", color: mainColor, description: "at peak" },
          { property: "avgPeak", color: darkColor, description: "average daily peak" },
        ]
      },
      {
        id: "atCapacityMinutes",
        customTagId: customTagId,
        type: WidgetGraphType.MonthlyDurationAtCapacity,
        valueType: WidgetValueType.Minutes,
        title: `Duration at capacity`,
        subtitle: `${lastMonth.format("MMMM YYYY")}`,
        resourceName: resourceName,
        singleResourceName: singleResourceName,
        description: `The total duration ${locationName} were at 100% capacity.`,
        dataLayout: [
          { property: "peak", description: lastMonthString, color: mainColor },
          { property: "peak", description: monthString, color: darkColor }
        ]
      }
    ];
  }

  let widgets = [
    {
      id: "utilizationDistributionMonth",
      type: WidgetGraphType.HorizontalCompareMonths,
      valueType: WidgetValueType.Count,
      dateKey: "datetime",
      title: `Unused ${resourceName}`,
      subtitle: `${lastMonth.format("MMMM YYYY")}`,
      resourceName: resourceName,
      singleResourceName: singleResourceName,
      description: `The number of ${resourceName} that had peak occupancy above 80% at least one workday in ${locationName}.`,
      dataLayout: [
        { property: "combinedDistribution[0].count", change: "combinedDistribution[0].countChange", datetime: lastMonth.toISOString(), color: mainColor, description: "0-10%" },
        { property: "combinedDistribution[1].count", change: "combinedDistribution[1].countChange", datetime: lastMonth.toISOString(), color: darkColor, description: "10-20%" }
      ],
      // dataDescription: "Resources above or below\nour peak usage indexes"
    },
    // {
    //   id: "utilizationDistributionMonth",
    //   type: WidgetGraphType.KPIBox,
    //   valueType: WidgetValueType.Percentage,
    //   dateKey: "month",
    //   title: `Meeting rooms with high usage`,
    //   description: `The number of meeting rooms that had peak occupancy above 80% at least one workday in ${locationName}.`,
    //   dataLayout: [
    //     { property: "highUsage", month: 0, description: lastLastLastLastMonthString },
    //     { property: "highUsage", month: 1, description: lastLastLastMonthString },
    //     { property: "highUsage", month: 2, description: lastLastMonthString },
    //     { property: "highUsage", month: 3, description: lastMonthString },
    //     { property: "highUsage", month: 4, description: monthString }
    //   ],
    //   // dataDescription: "Resources above or below\nour peak usage indexes"
    // },
    // {
    //   id: "utilizationDistributionMonth",
    //   type: WidgetGraphType.KPIBox,
    //   valueType: WidgetValueType.Count,
    //   dateKey: "datetime",
    //   title: `Monthly peak occupancy distribution ${monthString}`,
    //   description: `The number of meeting rooms that had a monthly peak occupancy within each %-interval in ${locationName}.`,
    //   dataLayout: [
    //     { property: "combinedDistribution[0].count", change: "combinedDistribution[0].change", datetime: lastMonth.toISOString(), color: mainColor, description: "0-20%" },
    //     { property: "combinedDistribution[1].count", change: "combinedDistribution[1].change", datetime: lastMonth.toISOString(), color: mainColor, description: "20-40%" },
    //     { property: "combinedDistribution[2].count", change: "combinedDistribution[2].change", datetime: lastMonth.toISOString(), color: mainColor, description: "40-60%" },
    //     { property: "combinedDistribution[3].count", change: "combinedDistribution[3].change", datetime: lastMonth.toISOString(), color: mainColor, description: "60-80%" },
    //     { property: "combinedDistribution[4].count", change: "combinedDistribution[4].change", datetime: lastMonth.toISOString(), color: mainColor, description: "80-100%" },
    //   ],
    //   // dataDescription: "Resources above or below\nour peak usage indexes"
    // },
    {
      id: "utilizationDistributionMonth",
      type: WidgetGraphType.MonthlyUtilizationDistribtion,
      valueType: WidgetValueType.Count,
      dateKey: "datetime",
      title: `Occupancy distribution`,
      subtitle: lastMonth.format("MMMM YYYY"),
      resourceName: resourceName,
      singleResourceName: singleResourceName,
      dataLayout: [
        { property: "combinedDistribution[0].count", change: "combinedDistribution[0].change", datetime: lastMonth.toISOString(), color: mainColor, description: "0-20%" },
        { property: "combinedDistribution[1].count", change: "combinedDistribution[1].change", datetime: lastMonth.toISOString(), color: mainColor, description: "20-40%" },
        { property: "combinedDistribution[2].count", change: "combinedDistribution[2].change", datetime: lastMonth.toISOString(), color: mainColor, description: "40-60%" },
        { property: "combinedDistribution[3].count", change: "combinedDistribution[3].change", datetime: lastMonth.toISOString(), color: mainColor, description: "60-80%" },
        { property: "combinedDistribution[4].count", change: "combinedDistribution[4].change", datetime: lastMonth.toISOString(), color: mainColor, description: "80-100%" },
      ],
    },

    {
      id: "utilizationMonthTopLocations",
      wpaCategoryNames: ["CollaborationSpace", "Meeting Rooms"],
      type: WidgetGraphType.DynamicValueList,
      valueType: WidgetValueType.Percentage,
      dateKey: "datetime",
      title: `Most popular ${resourceName}`,
      subtitle: lastMonth.format("MMMM YYYY"),
      description: `The ${resourceName} with the highest occupancy in ${locationName}.`,
      dataLayout: { property: "avgOccupancy", datetime: lastMonth.toISOString(), color: mainColor, nameProperty: "locationName" }
    },

    {
      id: "utilizationMonthBottomLocations",
      type: WidgetGraphType.DynamicValueList,
      valueType: WidgetValueType.Percentage,
      dateKey: "datetime",
      title: `Least popular ${resourceName}`,
      subtitle: lastMonth.format("MMMM YYYY"),
      description: `The ${resourceName} with the lowest occupancy in ${locationName}.`,
      dataLayout: { property: "avgOccupancy", datetime: lastMonth.toISOString(), color: mainColor, nameProperty: "locationName" }
    },
    // {
    //   id: "utilizationDistributionMonth",
    //   type: WidgetGraphType.KPIBox,
    //   valueType: WidgetValueType.Count,
    //   dateKey: "category",
    //   title: `Usage outliers ${monthString}`,
    //   description: `The number of meeting rooms that was too little or too much used in ${locationName}.`,
    //   dataLayout: [
    //     { property: "count", color: mainColor, category: -1, description: "too little" },
    //     // { property: "count", color: mainColor, category: 0, description: "perfect" },
    //     { property: "count", color: mainColor, category: 1, description: "too much" },
    //   ],
    //   // dataDescription: "Resources above or below\nour peak usage indexes"
    // },
    // {
    //   id: "utilizationIntraday",
    //   type: WidgetGraphType.VerticalBar,
    //   valueType: WidgetValueType.Percentage,
    //   dateKey: "hour",
    //   title: `Daily peak utilization`,
    //   subtitle: lastMonth.format("MMMM YYYY"),
    //   description: `The highest usage of resources by hour of the day in ${locationName}.`,
    //   dataLayout: [
    //     { property: "peakPeak", hour: 7, color: mainColor, description: "07-08" },
    //     { property: "peakPeak", hour: 8, color: mainColor, description: "" },
    //     { property: "peakPeak", hour: 9, color: mainColor, description: "" },
    //     { property: "peakPeak", hour: 10, color: mainColor, description: "" },
    //     { property: "peakPeak", hour: 11, color: mainColor, description: "" },
    //     { property: "peakPeak", hour: 12, color: mainColor, description: "" },
    //     { property: "peakPeak", hour: 13, color: mainColor, description: "" },
    //     { property: "peakPeak", hour: 14, color: mainColor, description: "" },
    //     { property: "peakPeak", hour: 15, color: mainColor, description: "" },
    //     { property: "peakPeak", hour: 16, color: mainColor, description: "16-17" },
    //   ],
    //   dataDescription: "Utilization\nat peak"
    // },
    {
      id: "utilizationWeekday",
      type: WidgetGraphType.VerticalBar,
      valueType: WidgetValueType.Percentage,
      dateKey: "weekday",
      title: `Weekly peak utilization`,
      subtitle: lastMonth.format("MMMM YYYY"),
      description: `The highest utilization reached each weekday in ${locationName}.`,
      dataLayout: [
        { property: "peakPeak", weekday: 1, color: mainColor, description: "Monday" },
        { property: "peakPeak", weekday: 2, color: mainColor, description: "" },
        { property: "peakPeak", weekday: 3, color: mainColor, description: "" },
        { property: "peakPeak", weekday: 4, color: mainColor, description: "" },
        { property: "peakPeak", weekday: 5, color: mainColor, description: "Friday" },
      ],
      // dataDescription: "Utilization\nat peak"
    },
    {
      id: "utilizationMonth",
      type: WidgetGraphType.HorizontalBar,
      valueType: WidgetValueType.Percentage,
      title: `Monthly peak and average utilization`,
      subtitle: lastMonth.format("MMMM YYYY"),
      description: `The highest and average utilization in ${locationName}.`,
      resourceName: resourceName,
      singleResourceName: singleResourceName,
      dataLayout: [
        { property: "peakPeak", color: mainColor, description: "at peak" },
        { property: "avgPeak", color: darkColor, description: "average daily peak" },
      ]
    },
    {
      id: "utilizationDay",
      type: WidgetGraphType.UtilizationSparklineMonth,
      valueType: WidgetValueType.Percentage,
      title: `Monthly peak utilization`,
      subtitle: lastMonth.format("MMMM YYYY"),
      color: mainColor,
      dataLayout: monthDailyArray.map((_, index) => ({ property: "peakPeak", description: index === 0 ? startDate.format("D. MMMM") : (index === monthDailyArray.length - 1 ? endDate.format("D. MMMM") : "") })),
    }
  ];

  if (page.name !== "ActivityZone") {
    widgets.push(
      {
        id: "notUtilizedMinutes",
        type: WidgetGraphType.MonthlyDurationEmpty,
        valueType: WidgetValueType.Minutes,
        title: "Everything available",
        subtitle: `${lastMonth.format("MMMM YYYY")}`,
        resourceName: resourceName,
        singleResourceName: singleResourceName,
        dataLayout: [
          { property: "peak", description: lastMonthString, color: mainColor },
          { property: "peak", description: monthString, color: darkColor }
        ],
      },
      // {
      //   id: "fullyUtilizedMinutes",
      //   type: WidgetGraphType.ChangeBarGraph,
      //   valueType: WidgetValueType.Minutes,
      //   title: `Duration with no availability`,
      //   subtitle: `${lastLastMonth.format("MMMM")} - ${lastMonth.format("MMMM YYYY")}`,
      //   description: `The duration all resources were exhauseted and 100% utilized in ${locationName}.`,
      //   dataLayout: [
      //     { property: "peak", description: lastMonthString, color: "#aaa" },
      //     { property: "peak", description: monthString, color: mainColor }
      //   ],
      //   dataDescription: "Total duration all\nresources were in use"
      // },
      {
        id: "fullyUtilizedMinutes",
        type: WidgetGraphType.MonthlyDurationFull,
        valueType: WidgetValueType.Minutes,
        title: "Nothing available",
        subtitle: `${lastMonth.format("MMMM YYYY")}`,
        resourceName: resourceName,
        singleResourceName: singleResourceName,
        dataLayout: [
          { property: "peak", description: lastMonthString, color: mainColor },
          { property: "peak", description: monthString, color: darkColor }
        ]
      },
      // {
      //   id: "neverUsedResourcesCount",
      //   type: WidgetGraphType.ChangeBarGraph,
      //   valueType: WidgetValueType.Count,
      //   title: `Resources never used`,
      //   subtitle: `${lastLastMonth.format("MMMM")} - ${lastMonth.format("MMMM YYYY")}`,
      //   description: `The number of resources that were never used in ${locationName}.`,
      //   dataLayout: [
      //     { property: "count", description: lastMonthString, color: "#aaa" },
      //     { property: "count", description: monthString, color: mainColor }
      //   ],
      //   dataDescription: "Number of\nunused resources"
      // }
    );
  }

  return widgets;
}

class FrontPage extends Component {

  constructor(props) {
    super(props);

    const params = new URLSearchParams(props.history.location.search);
    const companyId = getCompanyId();
    const favoriteBuildingId = localStorage.getItem(`df_${companyId}`);

    this.state = {
      regions: [],
      buildings: [],
      selectedBuildingId: params.get("b") ?? favoriteBuildingId ?? "",
      favBuildingId: favoriteBuildingId,
      locationHierarchy: null,
      selectedWpaCategoryId: params.get("c") ?? "overview",
      selectedCustomTagId: params.get("t") ?? null,
      hasLoadedView: false
    };

    this.fetchData = this.fetchData.bind(this);
    this.onBuildingClick = this.onBuildingClick.bind(this);
    this.onToggleFavorite = this.onToggleFavorite.bind(this);
    this.onSelectedPageChanged = this.onSelectedPageChanged.bind(this);

    if (isEmpty(this.props.wpaCategories)) {
      this.props.getWpaCategories();
    }

    if (isEmpty(this.props.locationHierarchy)) {
      this.props.getLocationHierarchy();
    }

    if (isEmpty(this.props.widgetProfile)) {
      this.props.getWidgetProfile(this.props.match.params.companyId);
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {

    // Run once when we get location hierarchy and wpa categories
    if (!prevState.hasLoadedView && !isEmpty(nextProps.locationHierarchy) && !isEmpty(nextProps.wpaCategories)) {

      // Get all locations of type "building"
      let newHierarchy = JSON.parse(JSON.stringify(nextProps.locationHierarchy));

      let topLocation = { id: "*", children: newHierarchy, name: "" };

      // Get all location from topLocation.children with type building
      const regions = [];
      const buildings = [];
      const getLocations = (location, parentRegion) => {

        // If location is a building, add it to the list
        if (location.id !== "*" && location.type === "building") {
          const region = parentRegion ? regions.find(region => region.id === parentRegion.id) : null;

          const widgets = [];

          if (region) {
            region.children.push({ ...location, widgets });
          }
          else {
            regions.push({ id: parentRegion.id, name: parentRegion.name, children: [{ ...location, widgets }] });
          }

          buildings.push({ ...location, widgets });
        }

        if (location.children) {
          let newParentRegion = parentRegion;
          if (location.type === "region") {
            newParentRegion = location;
          }
          location.children.forEach(child => getLocations(child, newParentRegion));
        }
      }
      getLocations(topLocation, { id: "*", name: "No region" });

      // Sort regions by name (but "*" last) and buildings by name
      regions.sort((a, b) => a.id === "*" ? 1 : b.id === "*" ? -1 : a.name.localeCompare(b.name, "nb-NO"));
      regions.forEach(region => region.children.sort((a, b) => a.name.localeCompare(b.name, "nb-NO")));

      // Add all buildings as the first child of the region
      regions.forEach(region => {
        if (region.children.length > 1) {

          const widgets = [];

          if (region.id !== "*") {
            region.children.unshift({ id: region.id, name: "All buildings", type: "region", widgets });
          }
        }
      });

      // Select first building in first region, if no building is selected
      if (!prevState.selectedBuildingId) {
        const firstRegion = regions.find(region => region.children.length > 0);
        if (firstRegion) {
          const firstBuilding = firstRegion.children[0];
          if (firstBuilding) {
            return { regions, buildings, locationHierarchy: nextProps.locationHierarchy, selectedBuildingId: firstBuilding.id };
          }
        }
      }

      return { regions, buildings, locationHierarchy: nextProps.locationHierarchy, hasLoadedView: true };
    }

    return null;
  }

  componentDidMount() {
    document.title = `BLDNG.ai - Home`;
    console.log("Home.FrontPage.componentDidMount");

    // Set moment locale every time the component mounts
    moment.locale("en");

    if (this.state.selectedBuildingId && this.props.widgetProfile && this.props.wpaCategories && this.props.locationHierarchy) {
      this.fetchData();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    console.log("Home.FrontPage.componentDidUpdate", this.state.selectedBuildingId, this.state.selectedWpaCategoryId);

    const buildingChanged = this.state.selectedBuildingId && this.state.selectedBuildingId !== prevState.selectedBuildingId;
    const wpaCategoryChanged = this.state.selectedWpaCategoryId !== prevState.selectedWpaCategoryId;

    if (this.state.hasLoadedView) {
      // If first load or building or wpa category has changed
      if (!prevState.hasLoadedView) {
        this.fetchData();
      }
      else if ((buildingChanged || wpaCategoryChanged) && this.props.widgetProfile) {
        this.fetchData();
      }
    }
  }

  fetchData() {
    const widgetQueries = [];
    if (this.state.selectedWpaCategoryId === "overview") {
      const overviewWidgets = get(this.props.widgetProfile, "overview.widgets", []);
      overviewWidgets.forEach(widget => {
        const buildingId = this.state.selectedBuildingId;
        const wpaCategoryId = widget.wpaCategoryId ?? null;
        const customTagId = widget.customTagId ?? null;
        const widgetKey = `${buildingId}-${wpaCategoryId}-${customTagId}`;
        if (!get(this.props.widgetData, `${widgetKey}.${widget.id}`, false)) {
          // console.log("widgetKey", get(this.props.widgetData, `${widgetKey}.${widget.id}`, false));
          widgetQueries.push({ id: widget.id, buildingId: this.state.selectedBuildingId, wpaCategoryId, customTagId });

          // switch (widget.id) {
          //   case "peopleCountIntraday":
          //     this.props.getPeopleCountIntraday(this.state.selectedBuildingId, customTagId);
          //     break;
          //   case "peopleCountWeekday":
          //     this.props.getPeopleCountWeekday(this.state.selectedBuildingId, customTagId);
          //     break;
          //   case "peopleCountMonth":
          //     this.props.getPeopleCountMonth(this.state.selectedBuildingId, customTagId);
          //     break;
          //   case "atCapacityMinutes":
          //     this.props.getAtCapacityMinutes(this.state.selectedBuildingId, customTagId);
          //     break;
          //   case "utilizationIntraday":
          //     this.props.getUtilizationIntraday(this.state.selectedBuildingId, wpaCategoryId, customTagId);
          //     break;
          //   case "utilizationDay":
          //     this.props.getUtilizationDay(this.state.selectedBuildingId, wpaCategoryId, customTagId);
          //     break;
          //   case "utilizationWeekday":
          //     this.props.getUtilizationWeekday(this.state.selectedBuildingId, wpaCategoryId, customTagId);
          //     break;
          //   case "utilizationMonth":
          //     this.props.getUtilizationMonth(this.state.selectedBuildingId, wpaCategoryId, customTagId);
          //     break;
          //   case "utilizationMonthlyDistribution":
          //     this.props.getUtilizationMonthlyDistribution(this.state.selectedBuildingId, wpaCategoryId, customTagId);
          //     break;
          //   case "utilizationMonthTopLocations":
          //     this.props.getUtilizationTopLocations(this.state.selectedBuildingId, wpaCategoryId, customTagId);
          //     break;
          //   case "utilizationMonthBottomLocations":
          //     this.props.getUtilizationBottomLocations(this.state.selectedBuildingId, wpaCategoryId, customTagId);
          //     break;
          //   default:
          //     break;
          // }
        }
      });
    }
    else if (this.state.selectedWpaCategoryId === null) {
      // Check if we have the data for the selected building
      const customTagId = get(this.props.widgetProfile, "peopleCount.customTagId", this.state.selectedCustomTagId);
      const widgetKey = `${this.state.selectedBuildingId}-${this.state.selectedWpaCategoryId}-${customTagId}`;
      const dataTypes = ["peopleCountIntraday", "peopleCountWeekday", "peopleCountMonth", "atCapacityMinutes"];
      dataTypes.forEach(dataType => {
        
        if (!get(this.props.widgetData, `${widgetKey}.${dataType}`, false)) {
          widgetQueries.push({ id: dataType, buildingId: this.state.selectedBuildingId, wpaCategoryId: null, customTagId });
          // console.log("widgetData", this.props.widgetData);
          // console.log("widgetKey", widgetKey);
          // console.log("dataType", dataType);
          // console.log("widget", get(this.props.widgetData, `${widgetKey}.${dataType}`, false));
          
          // switch (dataType) {
          //   case "peopleCountIntraday":
          //     this.props.getPeopleCountIntraday(this.state.selectedBuildingId, customTagId);
          //     break;
          //   case "peopleCountWeekday":
          //     this.props.getPeopleCountWeekday(this.state.selectedBuildingId, customTagId);
          //     break;
          //   case "peopleCountMonth":
          //     this.props.getPeopleCountMonth(this.state.selectedBuildingId, customTagId);
          //     break;
          //   case "atCapacityMinutes":
          //     this.props.getAtCapacityMinutes(this.state.selectedBuildingId, customTagId);
          //     break;
          //   default:
          //     break;
          // }
        }
      });
    }
    else {
      // Check if we have the data for the selected building
      const widgetKey = `${this.state.selectedBuildingId}-${this.state.selectedWpaCategoryId}-${this.state.selectedCustomTagId}`;
      const dataTypes = ["fullyUtilizedMinutes", "notUtilizedMinutes", "neverUsedResourcesCount", "utilizationIntraday", "utilizationDay", "utilizationWeekday", "utilizationMonth", "utilizationDistributionMonth", "utilizationMonthTopLocations", "utilizationMonthBottomLocations"];
      dataTypes.forEach(dataType => {
        if (!get(this.props.widgetData, `${widgetKey}.${dataType}`, false)) {
          widgetQueries.push({ id: dataType, buildingId: this.state.selectedBuildingId, wpaCategoryId: this.state.selectedWpaCategoryId, customTagId: this.state.selectedCustomTagId });
        }
      });
    }

    // Remove duplicate widget queries
    const uniqueQueries = widgetQueries.filter((query, index, self) =>
      index === self.findIndex(t => (
        t.id === query.id && t.buildingId === query.buildingId && t.wpaCategoryId === query.wpaCategoryId && t.customTagId === query.customTagId
      ))
    );

    if (uniqueQueries.length > 0) {
      uniqueQueries.forEach(query => {
        switch (query.id) {
          case "peopleCountIntraday":
            this.props.getPeopleCountIntraday(query.buildingId, query.customTagId);
            break;
          case "peopleCountWeekday":
            this.props.getPeopleCountWeekday(query.buildingId, query.customTagId);
            break;
          case "peopleCountMonth":
            this.props.getPeopleCountMonth(query.buildingId, query.customTagId);
            break;
          case "atCapacityMinutes":
            this.props.getAtCapacityMinutes(query.buildingId, query.customTagId);
            break;
          case "fullyUtilizedMinutes":
            this.props.getFullyUtilizedMinutes(query.buildingId, query.wpaCategoryId, query.customTagId);
            break;
          case "notUtilizedMinutes":
            this.props.getNotUtilizedMinutes(query.buildingId, query.wpaCategoryId, query.customTagId);
            break;
          case "neverUsedResourcesCount":
            this.props.getNeverUtilized(query.buildingId, query.wpaCategoryId, query.customTagId);
            break;
          case "utilizationIntraday":
            this.props.getUtilizationIntraday(query.buildingId, query.wpaCategoryId, query.customTagId);
            break;
          case "utilizationDay":
            this.props.getUtilizationDay(query.buildingId, query.wpaCategoryId, query.customTagId);
            break;
          case "utilizationWeekday":
            this.props.getUtilizationWeekday(query.buildingId, query.wpaCategoryId, query.customTagId);
            break;
          case "utilizationMonth":
            this.props.getUtilizationMonth(query.buildingId, query.wpaCategoryId, query.customTagId);
            break;
          case "utilizationDistributionMonth":
            this.props.getUtilizationMonthlyDistribution(query.buildingId, query.wpaCategoryId, query.customTagId);
            break;
          case "utilizationMonthTopLocations":
            this.props.getUtilizationTopLocations(query.buildingId, query.wpaCategoryId, query.customTagId);
            break;
          case "utilizationMonthBottomLocations":
            this.props.getUtilizationBottomLocations(query.buildingId, query.wpaCategoryId, query.customTagId);
            break;
          default:
            break;
        }
      });
    }
  }

  onBuildingClick(locationId) {
    const params = new URLSearchParams(this.props.history.location.search);
    params.set("b", locationId);
    this.props.history.push({
      pathname: this.props.history.location.pathname.substr(0, this.props.history.location.pathname.length),
      search: params.toString()
    });

    this.setState({ selectedBuildingId: locationId });
  }

  onReportClick(locationId, workplaceCategoryId) {
    const filters = [{
      lid: locationId,
      wpc: workplaceCategoryId
    }];

    // Default date range is last 30 days (minus last 2 days)
    const defaultStart = moment().subtract(1, "month").startOf("isoWeek");
    const lastAvailableDate = moment().subtract(2, "days").startOf("day");

    let defaultEnd = moment();
    if (defaultEnd.isAfter(lastAvailableDate)) {
      defaultEnd = lastAvailableDate.startOf("day");
    }

    const dateRanges = [{ startDate: defaultStart.toISOString(), endDate: defaultEnd.toISOString() }];
    this.props.setWorkplaceQuery(dateRanges, false, false, locationId, workplaceCategoryId, null);

    this.props.history.push(`/companies/${this.props.match.params.companyId}/reports?f=${compressToEncodedURIComponent(JSON.stringify(filters))}`);
  }

  onToggleFavorite(locationId) {
    this.setState({ favBuildingId: locationId });
    const companyId = getCompanyId();
    localStorage.setItem(`df_${companyId}`, locationId);
  }

  onSelectedPageChanged(id) {
    const params = new URLSearchParams(this.props.history.location.search);

    if (id === null) {
      params.delete("c");
    }
    else {
      params.set("c", id);
    }

    this.props.history.push({
      pathname: this.props.history.location.pathname.substr(0, this.props.history.location.pathname.length),
      search: params.toString()
    });
    this.setState({ selectedWpaCategoryId: id });
  }

  render() {
    const { isLoading } = this.props;
    console.log("Home.FrontPage.state", this.state)
    console.log("Home.FrontPage.props", this.props)

    if (isLoading) {
      return <Loader fullScreen />;
    }

    let selectedBuilding = null;
    if (this.state.selectedBuildingId && this.state.regions.length > 0) {
      // Find the region containing the selected building
      const selectedRegion = this.state.regions.find(region =>
        region.children.some(child => child.id === this.state.selectedBuildingId)
      );

      // Find the selected building
      if (selectedRegion) {
        selectedBuilding = selectedRegion.children.find(child => child.id === this.state.selectedBuildingId);

        if (selectedBuilding.type === "region") {
          selectedBuilding = { ...selectedBuilding, name: selectedRegion.name };
        }
      }
    }
    else if (this.state.regions.length > 0) {
      selectedBuilding = this.state.regions[0].children[0];
    }

    let pages = [];
    let selectedPage = null;
    let widgets = [];
    if (selectedBuilding) {
      // console.log("selectedBuidling", selectedBuilding);

      // Convert wpaCategories to pages
      this.props.wpaCategories.forEach(category => {

        // Get the color based on the category name
        let mainColor = WPAColors.OVERVIEW;
        let darkColor = WPAColorsDark.OVERVIEW;
        if (category.name === "CollaborationSpace" || category.name === "Meeting Rooms") {
          mainColor = WPAColors.COLLABORATION_ROOMS;
          darkColor = WPAColorsDark.COLLABORATION_ROOMS;
        }
        else if (category.name === "SupportingSpace" || category.name === "Quiet Rooms") {
          mainColor = WPAColors.SUPPORTING_ROOMS;
          darkColor = WPAColorsDark.SUPPORTING_ROOMS;
        }
        else if (category.name === "ActivityZone") {
          mainColor = WPAColors.ACTIVITY_ZONE;
          darkColor = WPAColorsDark.ACTIVITY_ZONE;
        }
        else if (category.name === "Workstations") {
          mainColor = WPAColors.WORKSTATIONS;
          darkColor = WPAColorsDark.WORKSTATIONS;
        }

        pages.push({
          id: category._id,
          name: category.name,
          color: mainColor,
          darkColor: darkColor
        });
      });
      
      pages.unshift({ id: "overview", name: "Overview", color: WPAColors.OVERVIEW, darkColor: WPAColorsDark.OVERVIEW });
      pages.push({ id: null, name: "People count", color: WPAColors.PEOPLE_COUNT, darkColor: WPAColorsDark.PEOPLE_COUNT });
      // pages.push({ id: "occupancy-list", name: "Occupancy list", color: WPAColors.PEOPLE_COUNT, darkColor: WPAColorsDark.PEOPLE_COUNT });
      // pages.push({ id: "people-count-list", name: "People count list", color: WPAColors.PEOPLE_COUNT, darkColor: WPAColorsDark.PEOPLE_COUNT });

      // Build widgets
      selectedPage = pages.find(page => page.id === this.state.selectedWpaCategoryId);
      widgets = getWidgets(selectedBuilding.name, this.props.widgetProfile, selectedPage);
    }

    return (
      <OuterContainer>
        <LayoutContainer>
          <SideBarContainer>
            <HomeSideBar
              regions={this.state.regions}
              selectedLocationId={this.state.selectedBuildingId}
              onLocationClick={this.onBuildingClick}
              isLoading={isLoading}
            />
          </SideBarContainer>
          <InnerContainer $showSideBar>
            <HomeHeader
              pages={pages}
              selectedPageId={selectedPage ? selectedPage.id : null}
              selectedLocation={selectedBuilding}
              favoriteLocationId={this.state.favBuildingId}
              onSelectedPageChanged={this.onSelectedPageChanged}
              onToggleFavorite={this.onToggleFavorite}
              isLoading={isLoading}
            />
            <WidgetGrid
              page={selectedPage}
              building={selectedBuilding}
              wpaCategoryId={this.state.selectedWpaCategoryId}
              customTagId={this.state.selectedCustomTagId}
              widgets={widgets}
            />
            {/* <Locations
              {...this.props}
              page={selectedPage}
              building={selectedBuilding}
              wpaCategoryId={this.state.selectedWpaCategoryId}
              customTagId={this.state.selectedCustomTagId}
              displayType="occupancy"
              onChangeView={this.onChangeView}
            /> */}
          </InnerContainer>
        </LayoutContainer>
      </OuterContainer>
    );
  }
}

function mapStateToProps(state) {
  return {
    company: state.auth.selectedCompany,
    locationHierarchy: state.locations.hierarchy,
    locationBreadcrumbs: state.locations.breadcrumbs,
    customTags: state.customTags.list,
    isLoading: state.loading.locations,
    widgetData: state.widgets.data,
    widgetLoading: state.widgets.loading,
    wpaCategories: state.report.wpaCategories,
    widgetProfile: state.widgets.profile,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getCustomTags: customTagActions.getCustomTags,
    getLocationHierarchy: locationActions.getLocationHierarchy,
    getFullyUtilizedMinutes: widgetActions.getFullyUtilizedMinutes,
    getNotUtilizedMinutes: widgetActions.getNotUtilizedMinutes,
    getNeverUtilized: widgetActions.getNeverUtilized,
    getAtCapacityMinutes: widgetActions.getAtCapacityMinutes,
    getUtilizationIntraday: widgetActions.getUtilizationIntraday,
    getUtilizationDay: widgetActions.getUtilizationDay,
    getUtilizationWeekday: widgetActions.getUtilizationWeekday,
    getUtilizationMonth: widgetActions.getUtilizationMonth,
    getUtilizationMonthlyDistribution: widgetActions.getUtilizationMonthlyDistribution,
    getUtilizationTopLocations: widgetActions.getUtilizationTopLocations,
    getUtilizationBottomLocations: widgetActions.getUtilizationBottomLocations,
    getPeopleCountIntraday: widgetActions.getPeopleCountIntraday,
    getPeopleCountDay: widgetActions.getPeopleCountDay,
    getPeopleCountWeekday: widgetActions.getPeopleCountWeekday,
    getPeopleCountMonth: widgetActions.getPeopleCountMonth,
    getWpaCategories: reportActions.getWpaCategories,
    getWidgetProfile: widgetActions.getWidgetProfile,
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(FrontPage);

const OuterContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 65px);
  width: 100%;
  background-color: #f6f6f6;

  h1, h2, h3, h4, h5, h6, p, ol, li {
    margin-top: 0;
  }

  h1 {
    margin-bottom: 20px;
  }

  h2, h3, h4, h5, h6 {
    margin-bottom: 15px;
  }

  p, ol {
    margin-bottom: 20px;
    font-size: 16px;
  }
`;

const LayoutContainer = styled.div`
  display: inline-flex;
  height: 100%;
  width: 100%;
  background-color: #f6f6f6;
`;

const SideBarContainer = styled.div`
  position: relative;
  min-width: 300px;
  max-width: 300px;
  max-height: 100%;
  height: 100%;
  overflow: auto;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
`;

const InnerContainer = styled.div`
  position: relative;
  // width: ${props => props.$showSideBar ? "calc(100% - 300px)" : "100%"};
  flex: 1;
  max-height: 100%;
  height: 100%;
  overflow: auto;

  padding-left: ${props => props.$mobile ? "20px" : "20px"};
  padding-right: ${props => props.$mobile ? "20px" : "20px"}; // 95 is from the menu bar and 40 is from the padding
  padding-top: 30px; // 40px
  padding-bottom: 40px;
  box-sizing: border-box;
  text-align: left;
`;