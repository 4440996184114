import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Col, Row, Visible, Hidden } from "react-grid-system";
import moment from "moment";
import { get, isEmpty } from "lodash";
import Table from "../../components/Table";
import Tag from "../../components/Tag";
import Loader from "../../components/Loader";
import SimpleSearchBox from "../../components/HomeSearchBox";
import ListCard from "../../components/ListCard";
import { locationFilterTypes } from "../../locationHelpers";
import * as dashboardActions from "../../actions/dashboards";
import * as locationActions from "../../actions/locations";
import * as sensorActions from "../../actions/sensors";
import * as gatewayActions from "../../actions/gateways";
import * as customTagActions from "../../actions/customTags";
import * as filterActions from "../../actions/filters";
import * as reportActions from "../../actions/reports";
import * as selectedActions from "../../actions/selected";
import style from "./style.module.scss";
import styled from "styled-components";

class SearchPage extends Component {

  constructor(props) {
    super(props);

    const params = new URLSearchParams(props.history.location.search);

    this.state = {
      search: params.get("q") ?? "",
      offlineSearch: "",
      sortBy: "name",
      sortOrder: "asc",
      offset: 0,
      limit: 20,
      searchType: "locations",
      showSearchTable: false,
      showOfflineSensors: false,
      showOfflineGateways: false,
      locationHierarchy: null
    };

    this.onChangeSearchType = this.onChangeSearchType.bind(this);
    this.onFetchData = this.onFetchData.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
    this.onSearchClear = this.onSearchClear.bind(this);
    this.onSearchKeyPress = this.onSearchKeyPress.bind(this);
    this.onChangeView = this.onChangeView.bind(this);
    this.onSortedChange = this.onSortedChange.bind(this);
    this.onOffsetChange = this.onOffsetChange.bind(this);
    this.onLocationClick = this.onLocationClick.bind(this);
    this.onSensorClick = this.onSensorClick.bind(this);
    this.onGatewayClick = this.onGatewayClick.bind(this);
    this.onHover = this.onHover.bind(this);
    this.locationTable = this.locationTable.bind(this);
    this.onToggleAllLocations = this.onToggleAllLocations.bind(this);
    this.onToggleAllSensors = this.onToggleAllSensors.bind(this);
    this.onToggleAllGateways = this.onToggleAllGateways.bind(this);

    if (get(props, "offlineSensors.sensors", []).length === 0) {
      props.getOfflineSensors();
    }

    if (get(props, "offlineGateways.gateways", []).length === 0) {
      props.getOfflineGateways();
    }

    if (isEmpty(this.props.customTags)) {
      this.props.getCustomTags();
    }

    if (isEmpty(this.props.locationHierarchy)) {
      this.props.getLocationHierarchy();
    }

    this.onFetchData();
  }

  componentDidMount() {
    document.title = `BLDNG.ai - Search`;
  }

  onChangeSearchType(event) {
    this.setState({ searchType: event.target.value, showOfflineSensors: false, showOfflineGateways: false, sortBy: "name", sortOrder: "asc" }, this.onFetchData);
  }

  onSearchKeyPress(event) {
    if (event.key && event.key === "Enter") {
      event.preventDefault();
      this.onFetchData();
    }
  }

  onFetchData() {
    console.log("onFetchData", this.state);
    const queryParams = {};
    if (this.state.showOfflineGateways || this.state.showOfflineSensors) {
      this.setState({ showSearchTable: true, offlineSearch: this.state.search });
    }
    else if (this.state.search !== null && this.state.search !== "") {
      queryParams.search = this.state.search;

      queryParams.offset = this.state.offset;
      queryParams.limit = this.state.limit;
      queryParams.sortBy = this.state.sortBy;
      queryParams.sortOrder = this.state.sortOrder;

      this.setState({ showSearchTable: true });

      if (this.state.searchType === "locations") {
        this.props.getLocations(queryParams);
      }
      else if (this.state.searchType === "sensors") {
        this.props.getSensors(queryParams);
      }
      else if (this.state.searchType === "gateways") {
        this.props.getGateways(queryParams);
      }
    }
    else {
      this.setState({ showSearchTable: false });
    }
  }

  onSearchChange(value) {
    this.setState({ search: value, offset: 0});
  }

  onSearchClear() {
    this.setState({ search: "", showOfflineGateways: false, showOfflineSensors: false, showSearchTable: false, offset: 0});
  }

  onChangeView(view) {
    this.setState({ showSearchTable: view !== "dashboard" });
  }

  onSortedChange(newSorted) {
    const sortBy = newSorted[0].id;
    const sortOrder = newSorted[0].desc ? "desc" : "asc";
    this.setState({ sortBy, sortOrder }, () => {
      if (!this.state.showOfflineGateways && !this.state.showOfflineSensors) {
        this.onFetchData();
      }
    });
  }

  onOffsetChange(offset) {
    this.setState({ offset }, this.onFetchData);
  }

  onLocationClick(column, row) {
    return {
      onClick: e => {
        // console.log(row);
        if (row && column.name !== 'isSelected') {

          let link = `/companies/${this.props.match.params.companyId}/locations/${row.original._id}/locations/`;

          if (e.metaKey || e.ctrlKey) {
            window.open(`${link}`);
          }
          else {
            this.props.history.push(link);
          }
        }
      }
    }
  }

  onSensorClick(column, row) {
    return {
      onClick: e => {
        // console.log(row);
        if (row && column.name !== 'isSelected') {

          let link = `/companies/${this.props.match.params.companyId}/sensors/${row.original.id}`;

          if (e.metaKey || e.ctrlKey) {
            window.open(`${link}`);
          }
          else {
            this.props.history.push(link);
          }
        }
      }
    }
  }

  onGatewayClick(column, row) {
    return {
      onClick: e => {
        // console.log(row);
        if (row && column.name !== 'isSelected') {

          let link = `/companies/${this.props.match.params.companyId}/gateways/${row.original.id}`;

          if (e.metaKey || e.ctrlKey) {
            window.open(`${link}`);
          }
          else {
            this.props.history.push(link);
          }
        }
      }
    }
  }

  onHover(id) {
    if (this.state.highlightedId !== id) {
      this.setState({ highlightedId: id });
    }
  }

  onToggleLocation(row) {
    if (this.props.selectedLocations[row.original._id] !== undefined && this.props.selectedLocations[row.original._id]) {
      this.props.deselectLocation(row.original._id);
    }
    else {
      this.props.selectLocation(row.original);
    }
  }

  onToggleAllLocations() {
    const locations = get(this.props.locations, "data.locations", []);
    const allLocationsAreSelected = !isEmpty(locations) && locations.every(location => this.props.selectedLocations[location._id] !== undefined && this.props.selectedLocations[location._id]);

    if (allLocationsAreSelected) {
      this.props.deselectLocations(locations);
    }
    else {
      this.props.selectLocations(locations.map(location => {
        return { ...location };
      }));
    }
  }

  onToggleSensor(row) {
    if (this.props.selectedSensors[row.original.id] !== undefined && this.props.selectedSensors[row.original.id]) {
      this.props.deselectSensor(row.original.id);
    }
    else {
      this.props.selectSensor(row.original);
    }
  }

  onToggleAllSensors() {
    let sensors;
    if (this.state.showOfflineSensors) {
      sensors = get(this.props.offlineSensors, "sensors", []);

      if (this.state.offlineSearch !== "") {
        sensors = sensors.filter(sensor => sensor.name && sensor.name.toLowerCase().includes(this.state.offlineSearch.toLowerCase()));
      }
    }
    else {
      sensors = get(this.props.sensors, "metricData.results", []);
    }

    const allSensorsAreSelected = !isEmpty(sensors) && sensors.every(sensor => this.props.selectedSensors[sensor.id] !== undefined && this.props.selectedSensors[sensor.id]);

    if (allSensorsAreSelected) {
      this.props.deselectSensors(sensors);
    }
    else {
      this.props.selectSensors(sensors.map(sensor => {
        return { ...sensor };
      }));
    }
  }

  onToggleGateway(row) {
    if (this.props.selectedGateways[row.original.id] !== undefined && this.props.selectedGateways[row.original.id]) {
      this.props.deselectGateway(row.original.id);
    }
    else {
      this.props.selectGateway(row.original);
    }
  }
  
  onToggleAllGateways() {
    let gateways;
    if (this.state.showOfflineGateways) {
      gateways = get(this.props.offlineGateways, "gateways", []);

      if (this.state.offlineSearch !== "") {
        gateways = gateways.filter(gateway => gateway.name && gateway.name.toLowerCase().includes(this.state.offlineSearch.toLowerCase()));
      }
    }
    else {
      gateways = get(this.props.gateways, "metricData.results", []);
    }

    const allGatewaysAreSelected = !isEmpty(gateways) && gateways.every(gateway => this.props.selectedGateways[gateway.id] !== undefined && this.props.selectedGateways[gateway.id]);

    if (allGatewaysAreSelected) {
      this.props.deselectGateways(gateways);
    }
    else {
      this.props.selectGateways(gateways.map(gateway => {
        return { ...gateway };
      }));
    }
  }

  locationTable() {

    const locations = get(this.props.locations, "data.locations", []);
    const count = get(this.props.locations, "data.count", 0);

    const allLocationsAreSelected = !isEmpty(locations) && locations.every(location => this.props.selectedLocations[location._id] !== undefined && this.props.selectedLocations[location._id]);

    return (
      <Table
        data={locations}
        sortBy={this.state.sortBy}
        sortOrder={this.state.sortOrder}
        offset={this.state.offset}
        limit={this.state.limit}
        count={count}
        onOffsetChange={this.onOffsetChange}
        onSortedChange={this.onSortedChange}
        noDataText={this.props.isLoading ? "" : "No locations found"}
        columns={[
          {
            id: "locationId",
            header: "",
            accessorKey: "_id",
            sortable: false,
            name: "isSelected",
            header: () => (
              <label className="checkboxContainer checkboxHeaderContainer" htmlFor={`editCheckbox-header`}>
                <input
                  id={`editCheckbox-header`}
                  type="checkbox"
                  className="checkbox"
                  checked={allLocationsAreSelected}
                  onChange={() => this.onToggleAllLocations()}
                  disabled={isEmpty(locations)}
                />
                <span className={isEmpty(locations) ? "disabledCheckmark" : "checkmark"} />
              </label>
            ),
            cell: ({ row }) => (
              <label className="checkboxContainer" htmlFor={`editCheckbox-${row.original._id}`}>
                <input
                  id={`editCheckbox-${row.original._id}`}
                  type="checkbox"
                  className="checkbox"
                  checked={(this.props.selectedLocations[row.original._id] !== undefined && this.props.selectedLocations[row.original._id])}
                  onChange={() => this.onToggleLocation(row)}
                />
                <span className="checkmark" />
              </label>
            ),
            width: 60
          },
          {
            header: "Name",
            accessorKey: "name",
            minWidth: 100,
            maxWidth: 500,
            cell: ({ row }) => {
              let breadcrumbs = this.props.locationBreadcrumbs[row.original._id].map(breadcrumb => breadcrumb.name);
              breadcrumbs.pop();
              breadcrumbs = breadcrumbs.join(", ");
              if (breadcrumbs) {
                return (<><span title={row.original.name}>{row.original.name}</span><span title={breadcrumbs} style={{ color: "grey", marginLeft: "10px" }}> ({breadcrumbs})</span></>)
              }
              return (<span title={row.original.name}>{row.original.name}</span>)
            }
          },
          {
            header: "Type",
            accessorKey: "type",
            minWidth: 50,
            maxWidth: 300,
            cell: ({ row }) => {
              const locationType = locationFilterTypes.find(locationType => locationType.id === row.original.type);
              const name = get(locationType, "name", row.original.type);
              return <span title={name}>{name}</span>
            }
          },
          {
            header: "Tags",
            accessorKey: "customTags",
            minWidth: 100,
            cell: ({ row }) => {
              if (row["customTags"]) {
                const norway = "nb-NO";
                return row["customTags"]
                  .map(tagId => this.props.customTags.find(tag => tag.id === tagId))
                  .filter(n => n)
                  .sort((a, b) => a.name.localeCompare(b.name, norway))
                  .map(tag => <Tag key={tag.id} text={tag.name} color={tag.colorTheme} />);
              }
              else {
                return null;
              }
            }
          },
          {
            id: "arrow",
            header: "",
            sortable: false,
            className: "pull-right",
            width: 60,
            cell: ({ row }) => (<div className="arrow" />)
          }
        ]}
        getTdProps={this.onLocationClick}
        getTrGroupProps={this.getTrProps}
        className="-row-clickable setMaxHeigth -highlight"
        loading={this.props.isLoading}
      />
    );
  }

  sensorTable() {

    let sensors;
    let count;
    if (this.state.showOfflineSensors) {
      sensors = get(this.props.offlineSensors, "sensors", []);
      count = get(this.props.offlineSensors, "count", 0);

      if (this.state.offlineSearch !== "") {
        sensors = sensors.filter(sensor => sensor.name && sensor.name.toLowerCase().includes(this.state.offlineSearch.toLowerCase()));
      }

      // Sort sensors by sortBy and sortOrder
      sensors = sensors.sort((a, b) => {
        const norway = "nb-NO";
        if (this.state.sortBy === "name") {
          return this.state.sortOrder === "asc" ? get(a, "name", "").localeCompare(get(b, "name", ""), norway) : get(b, "name", "").localeCompare(get(a, "name", ""), norway);
        }
        else if (this.state.sortBy === "model") {
          return this.state.sortOrder === "asc" ? get(a, "model", "").localeCompare(get(b, "model", "")) : get(b, "model", "").localeCompare(get(a, "model", ""));
        }
        else if (this.state.sortBy === "vendor") {
          return this.state.sortOrder === "asc" ? get(a, "vendor", "").localeCompare(get(b, "vendor", "")) : get(b, "vendor", "").localeCompare(get(a, "vendor", ""));
        }
        else if (this.state.sortBy === "gatewayDeviceId") {
          const aGateway = a.gatewayDeviceId ? a.gatewayDeviceId : "";
          const bGateway = b.gatewayDeviceId ? b.gatewayDeviceId : "";
          return this.state.sortOrder === "asc" ? aGateway.localeCompare(bGateway) : bGateway.localeCompare(aGateway);
        }
        else if (this.state.sortBy === "lastSampleAt") {
          return this.state.sortOrder === "asc" ? moment(a.lastSampleAt).diff(moment(b.lastSampleAt)) : moment(b.lastSampleAt).diff(moment(a.lastSampleAt));
        }
      });

      // Fix reload issue
      sensors = sensors.map(sensor => sensor);
    }
    else {
      sensors = get(this.props.sensors, "metricData.results", []);
      count = get(this.props.sensors, "metricData.count", 0);
    }

    const allSensorsAreSelected = !isEmpty(sensors) && sensors.every(sensor => this.props.selectedSensors[sensor.id] !== undefined && this.props.selectedSensors[sensor.id]);

    let columns = [
      {
        id: "sensorId",
        header: "",
        accessorKey: "id",
        sortable: false,
        name: "isSelected",
        header: () => (
          <label className="checkboxContainer checkboxHeaderContainer" htmlFor={`editCheckbox-header`}>
            <input
              id={`editCheckbox-header`}
              type="checkbox"
              className="checkbox"
              checked={allSensorsAreSelected}
              onChange={() => this.onToggleAllSensors()}
              disabled={isEmpty(sensors)}
            />
            <span className={isEmpty(sensors) ? "disabledCheckmark" : "checkmark"} />
          </label>
        ),
        cell: ({ row }) => (
          <label className="checkboxContainer" htmlFor={`editCheckbox-${row.original.id}`}>
            <input
              id={`editCheckbox-${row.original.id}`}
              type="checkbox"
              className="checkbox"
              checked={(this.props.selectedSensors[row.original.id] !== undefined && this.props.selectedSensors[row.original.id])}
              onChange={() => this.onToggleSensor(row)}
            />
            <span className="checkmark" />
          </label>
        ),
        width: 60
      },
      {
        header: "Name",
        accessorKey: "name",
        minWidth: 100,
        maxWidth: 1200,
        cell: ({ row }) => {
          if (row.original.locationId) {
            const breadcrumbs = this.props.locationBreadcrumbs[row.original.locationId].map(breadcrumb => breadcrumb.name).join(", ");
            return (<><span title={row.original.name}>{row.original.name}</span><span title={breadcrumbs} style={{ color: "grey", marginLeft: "10px" }}> ({breadcrumbs})</span></>)
          }
          return (<span title={row.original.name}>{row.original.name}</span>)
        }
      },
      {
        header: "Model",
        accessorKey: "model",
        minWidth: 100,
        maxWidth: 200,
        cell: ({ row }) => (<span>{row.original.model}</span>)
      },
      {
        header: "Vendor",
        accessorKey: "vendor",
        minWidth: 100,
        maxWidth: 200,
        cell: ({ row }) => (<span>{row.original.vendor}</span>)
      },
      {
        header: "Gateway",
        accessorKey: "gatewayDeviceId",
        minWidth: 100,
        maxWidth: 300,
        cell: ({ row }) => (<span>{row.original.gatewayDeviceId}</span>)
      },
      {
        id: "arrow",
        header: "",
        accessorKey: "properties",
        sortable: false,
        className: "pull-right",
        width: 60,
        cell: () => <div className="arrow" />
      }
    ];

    if (this.state.showOfflineSensors) {
      // insert lastSampleAt column at next to last position
      columns.splice(columns.length - 1, 0, {
        header: "Last sample",
        accessorKey: "lastSampleAt",
        width: 140,
        cell: ({ row }) => (<span>{row.original.lastSampleAt ? moment(row.original.lastSampleAt).format("DD/MM/YY, HH:mm") : "N/A"}</span>)
      });
    }

    return (
      <Table
        data={sensors}
        sortBy={this.state.sortBy}
        sortOrder={this.state.sortOrder}
        offset={this.state.offset}
        limit={this.state.limit}
        count={count}
        onOffsetChange={this.onOffsetChange}
        onSortedChange={this.onSortedChange}
        noDataText={this.props.isLoading ? "" : "No sensors found"}
        columns={columns}
        getTdProps={this.onSensorClick}
        getTrGroupProps={this.getTrProps}
        className="-row-clickable setMaxHeigth -highlight"
        loading={this.props.isLoading}
      />
    );
  }

  gatewayTable() {

    let gateways;
    let count;
    let limit;
    if (this.state.showOfflineGateways) {
      gateways = get(this.props.offlineGateways, "gateways", []);
      count = get(this.props.offlineGateways, "count", 0);
      limit = get(this.props.offlineGateways, "limit", 20);

      if (this.state.offlineSearch !== "") {
        gateways = gateways.filter(gateway => gateway.name && gateway.name.toLowerCase().includes(this.state.offlineSearch.toLowerCase()));
      }

      // Sort gateways by sortBy and sortOrder
      gateways = gateways.sort((a, b) => {
        const norway = "nb-NO";
        if (this.state.sortBy === "name") {
          return this.state.sortOrder === "asc" ? get(a, "name", "").localeCompare(get(b, "name", ""), norway) : get(b, "name", "").localeCompare(get(a, "name", ""), norway);
        }
        else if (this.state.sortBy === "vendor") {
          return this.state.sortOrder === "asc" ? get(a, "vendor", "").localeCompare(get(b, "vendor", "")) : get(b, "vendor", "").localeCompare(get(a, "vendor", ""));
        }
        else if (this.state.sortBy === "lastSampleAt") {
          return this.state.sortOrder === "asc" ? moment(a.lastSampleAt).diff(moment(b.lastSampleAt)) : moment(b.lastSampleAt).diff(moment(a.lastSampleAt));
        }
      });

      // Fix reload issue
      gateways = gateways.map(gateway => gateway);
    }
    else {
      gateways = get(this.props.gateways, "metricData.results", []);
      count = get(this.props.gateways, "metricData.count", 0);
      limit = get(this.props.gateways, "metricData.limit", 20);
    }

    const allGatewaysAreSelected = !isEmpty(gateways) && gateways.every(gateway => this.props.selectedGateways[gateway.id] !== undefined && this.props.selectedGateways[gateway.id]);

    let columns = [
      {
        id: "gatewayId",
        header: "",
        accessorKey: "id",
        sortable: false,
        name: "isSelected",
        // header: () => (
        //   <label className="checkboxContainer checkboxHeaderContainer" htmlFor={`editCheckbox-header`}>
        //     <input
        //       id={`editCheckbox-header`}
        //       type="checkbox"
        //       className="checkbox"
        //       checked={allGatewaysAreSelected}
        //       onChange={() => this.onToggleAllGateways()}
        //       disabled={isEmpty(gateways)}
        //     />
        //     <span className={isEmpty(gateways) ? "disabledCheckmark" : "checkmark"} />
        //   </label>
        // ),
        cell: ({ row }) => (
          <label className="checkboxContainer" htmlFor={`editCheckbox-${row.original.id}`}>
            <input
              id={`editCheckbox-${row.original.id}`}
              type="checkbox"
              className="checkbox"
              checked={(this.props.selectedGateways[row.original.id] !== undefined && this.props.selectedGateways[row.original.id])}
              onChange={() => this.onToggleGateway(row)}
            />
            <span className="checkmark" />
          </label>
        ),
        width: 60
      },
      {
        header: "Name",
        accessorKey: "name",
        cell: ({ row }) => {
          if (row.original.locationId) {
            const breadcrumbs = this.props.locationBreadcrumbs[row.original.locationId].map(breadcrumb => breadcrumb.name).join(", ");
            return (<><span title={row.original.name}>{row.original.name}</span><span title={breadcrumbs} style={{ color: "grey", marginLeft: "10px" }}> ({breadcrumbs})</span></>)
          }
          return (<span>{row.original.name ? row.original.name : row.original.deviceId}{row.original.locationId}</span>)
        }
      },
      {
        header: "Vendor",
        accessorKey: "vendor",
        cell: ({ row }) => (<span>{row.original.vendor}</span>)
      },
      {
        id: "arrow",
        header: "",
        accessorKey: "properties",
        sortable: false,
        className: "pull-right",
        width: 60,
        cell: () => <div className="arrow" />
      }
    ];

    if (this.state.showOfflineGateways) {
      // insert lastSampleAt column at next to last position
      columns.splice(columns.length - 1, 0, {
        header: "Last sample",
        accessorKey: "lastSampleAt",
        width: 140,
        cell: ({ row }) => (<span>{row.original.lastSampleAt ? moment(row.original.lastSampleAt).format("DD/MM/YY, HH:mm") : "N/A"}</span>)
      });
    }

    return (
      <Table
        data={gateways}
        page={this.state.page}
        pages={Math.ceil(count / limit)}
        sorted={[{
          id: this.state.sortBy,
          desc: this.state.sortOrder === "desc"
        }]}
        onOffsetChange={this.onOffsetChange}
        onSortedChange={this.onSortedChange}
        noDataText={this.props.isLoading ? "" : "No gateways found"}
        columns={columns}
        getTdProps={this.onGatewayClick}
        getTrGroupProps={this.getTrProps}
        className="-row-clickable setMaxHeigth -highlight"
        loading={this.props.isLoading}
      />
    );
  }

  render() {
    const { isLoading } = this.props;
    // console.log("Home.FrontPage.state", this.state)
    // console.log("Home.FrontPage.props", this.props)

    if (isLoading) {
      return <Loader fullScreen />;
    }

    let customTags = [];

    // Add offline gateways tag to searchbox, if state.offlineGateways is set
    if (this.state.showOfflineGateways) {
      customTags.push({
        id: "offlineGateways",
        name: "Offline gateways",
        colorTheme: "#BD271F"
      });
    }

    if (this.state.showOfflineSensors) {
      customTags.push({
        id: "offlineSensors",
        name: "Offline sensors",
        colorTheme: "#BD271F"
      });
    }

    const searchBox = (
      <SimpleSearchBox
        query={{ customTags, text: this.state.search, placeholder: "Search", searchType: this.state.searchType }}
        onQueryChange={this.onSearchChange}
        onChangeSearchType={this.onChangeSearchType}
        onSearchClick={() => this.setState({ offset: 0 }, this.onFetchData)}
        onKeyDown={this.onSearchKeyPress}
        onClear={this.onSearchClear}
        onRemoveTagClick={this.onRemoveTagClick}
      />
    );

    let heading = (
      <div style={{ marginLeft: "auto", marginRight: "auto", flexGrow: 1, maxWidth: "800px", marginTop: "60px", marginBottom: "60px" }}>
        {searchBox}
      </div>
    );

    let searchTable;
    if (this.state.searchType === "locations") {
      searchTable = this.locationTable();
    }
    else if (this.state.searchType === "sensors") {
      searchTable = this.sensorTable();
    }
    else if (this.state.searchType === "gateways") {
      searchTable = this.gatewayTable();
    }

    const content = (
      <>
        
      </>
    );

    return (
      <OuterContainer>
          <InnerContainer>
            { heading }
            <Row>
              <div className={style.widthContainer}>
                <div className={style.block}>
                  { searchTable }
                </div>
              </div>
            </Row>
          </InnerContainer>
      </OuterContainer>
    );
  }
}

function mapStateToProps(state) {
  return {
    company: state.auth.selectedCompany,
    locations: state.locations,
    locationHierarchy: state.locations.hierarchy,
    locationBreadcrumbs: state.locations.breadcrumbs,
    sensors: state.sensors,
    gateways: state.gateways,
    customTags: state.customTags.list,
    offlineSensors: state.offlineSensors.data,
    offlineGateways: state.offlineGateways.data,
    isLoading: state.loading.locations,
    selectedLocations: state.selected.locations,
    selectedSensors: state.selected.sensors,
    selectedGateways: state.selected.gateways,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getOfflineSensors: dashboardActions.getOfflineSensors,
    getOfflineGateways: dashboardActions.getOfflineGateways,
    getFilters: filterActions.getFilters,
    setFilter: filterActions.setFilter,
    getLocations: locationActions.getLocations,
    getSensors: sensorActions.getAllSensors,
    getGateways: gatewayActions.getAllGateways,
    getCustomTags: customTagActions.getCustomTags,
    getLocationHierarchy: locationActions.getLocationHierarchy,
    getSystemUptime: dashboardActions.getSystemUptime,
    getOccupancyMetrics: dashboardActions.getOccupancyMetrics,
    getUniqueUserLogins: dashboardActions.getUniqueUserLogins,
    selectLocation: selectedActions.selectLocation,
    deselectLocation: selectedActions.deselectLocation,
    selectLocations: selectedActions.selectLocations,
    deselectLocations: selectedActions.deselectLocations,
    selectSensor: selectedActions.selectSensor,
    deselectSensor: selectedActions.deselectSensor,
    selectSensors: selectedActions.selectSensors,
    deselectSensors: selectedActions.deselectSensors,
    selectGateway: selectedActions.selectGateway,
    deselectGateway: selectedActions.deselectGateway,
    selectGateways: selectedActions.selectGateways,
    deselectGateways: selectedActions.deselectGateways,
    setWorkplaceQuery: reportActions.setWorkplaceQuery,
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchPage);

const OuterContainer = styled.div`
  position: relative;
  display: block;
  height: calc(100vh - 65px);
  width: 100%;
  background-color: #f6f6f6;

  h1, h2, h3, h4, h5, h6, p, ol, li {
    margin-top: 0;
  }

  h1 {
    margin-bottom: 20px;
  }

  h2, h3, h4, h5, h6 {
    margin-bottom: 15px;
  }

  p, ol {
    margin-bottom: 20px;
    font-size: 16px;
  }
`;

const InnerContainer = styled.div`
  position: relative;
  width: ${props => props.$showSideBar ? "calc(100% - 345px)" : "100%"};
  max-height: 100%;
  height: 100%;
  overflow: auto;

  padding-left: ${props => props.$mobile ? "20px" : "40px"};
  padding-right: ${props => props.$mobile ? "20px" : "40px"};
  padding-bottom: 40px;
  box-sizing: border-box;
`;

const Options = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 55px;
  min-width: 55px;
  height: 100%;
  cursor: pointer;
  z-index: 2;
  padding-right: 20px;
  padding-left: 20px;
  cursor: default;
`;