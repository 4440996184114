import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Col, Row, Visible, Hidden } from "react-grid-system";
import moment from "moment";
import { get, isEmpty } from "lodash";
import { faChartBar, faChartColumn, faExclamation, faStar } from "@fortawesome/free-solid-svg-icons";
import { faStar as faEmptyStar } from "@fortawesome/free-regular-svg-icons";
import { compressToEncodedURIComponent, decompressFromEncodedURIComponent } from "lz-string";
import { RowIcon, GREEN, RED, DARKGREY } from "../../components/RowIcon";
import Table from "../../components/Table";
import Tag from "../../components/Tag";
import Loader from "../../components/Loader";
import SimpleSearchBox from "../../components/HomeSearchBox";
import BuildingCard from "./BuildingCard";
import ListCard from "../../components/ListCard";
import AppUsageCard from "./AppUsageCard";
import { locationFilterTypes } from "../../locationHelpers";
import * as dashboardActions from "../../actions/dashboards";
import * as locationActions from "../../actions/locations";
import * as sensorActions from "../../actions/sensors";
import * as gatewayActions from "../../actions/gateways";
import * as customTagActions from "../../actions/customTags";
import * as filterActions from "../../actions/filters";
import * as reportActions from "../../actions/reports";
import * as selectedActions from "../../actions/selected";
import style from "./style.module.scss";
import styled from "styled-components";
import HorizontalBarGraph from "./Widget/horizontalBarGraph";
import { faCircleQuestion, faLanguage, faThumbTack } from "@fortawesome/pro-duotone-svg-icons";
import OptionButton from "../Reports/optionButton";

class OldFrontPage extends Component {

  constructor(props) {
    super(props);

    const params = new URLSearchParams(props.history.location.search);

    this.state = {
      search: "",
      offlineSearch: "",
      sortBy: "name",
      sortOrder: "asc",
      offset: 0,
      limit: 20,
      searchType: "locations",
      showSearchTable: false,
      showOfflineSensors: false,
      showOfflineGateways: false,
      appUsageExpanded: false,
      expanded: {},
      favorited: {},
      regions: [],
      buildings: [],
      selectedBuildingId: params.get("b") ?? localStorage.getItem("dash_favorite") ?? "",
      favBuildingId: localStorage.getItem("dash_favorite"),
      locationHierarchy: null
    };

    this.onChangeSearchType = this.onChangeSearchType.bind(this);
    this.onFetchData = this.onFetchData.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
    this.onSearchClear = this.onSearchClear.bind(this);
    this.onSearchKeyPress = this.onSearchKeyPress.bind(this);
    this.onChangeView = this.onChangeView.bind(this);
    this.onSortedChange = this.onSortedChange.bind(this);
    this.onOffsetChange = this.onOffsetChange.bind(this);
    this.onLocationClick = this.onLocationClick.bind(this);
    this.onSensorClick = this.onSensorClick.bind(this);
    this.onGatewayClick = this.onGatewayClick.bind(this);
    this.onStatusClick = this.onStatusClick.bind(this);
    this.onFilterClick = this.onFilterClick.bind(this);
    this.onBuildingClick = this.onBuildingClick.bind(this);
    this.onHover = this.onHover.bind(this);
    this.onRemoveTagClick = this.onRemoveTagClick.bind(this);
    this.onToggleExpand = this.onToggleExpand.bind(this);
    this.onToggleAppUsageExpand = this.onToggleAppUsageExpand.bind(this);
    this.onToggleFavorite = this.onToggleFavorite.bind(this);
    this.onChartClick = this.onChartClick.bind(this);
    this.locationTable = this.locationTable.bind(this);
    this.statusTable = this.statusTable.bind(this);
    this.savedFilterTable = this.savedFilterTable.bind(this);
    this.onToggleAllLocations = this.onToggleAllLocations.bind(this);
    this.onToggleAllSensors = this.onToggleAllSensors.bind(this);
    this.onToggleAllGateways = this.onToggleAllGateways.bind(this);

    // Add favorites
    const favs = JSON.parse(localStorage.getItem("dash_favorites")) ?? [];
    this.state.favorited = favs.reduce((acc, curr) => (acc[curr] = true, acc), {}) ?? {};
    this.state.expanded = favs.reduce((acc, curr) => (acc[curr] = true, acc), {}) ?? {};

    if (get(props, "offlineSensors.sensors", []).length === 0) {
      props.getOfflineSensors();
    }

    if (get(props, "offlineGateways.gateways", []).length === 0) {
      props.getOfflineGateways();
    }

    if (get(props, "sumUptime", null) === null) {
      props.getSystemUptime();
    }

    if (get(props, "company.dashboardFeatures.showApp", false)) {
      props.getUniqueUserLogins();
    }

    props.getFilters();

    if (isEmpty(this.props.customTags)) {
      this.props.getCustomTags();
    }

    if (isEmpty(this.props.locationHierarchy)) {
      this.props.getLocationHierarchy();
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {

    // Run once when we get location hierarchy
    if (!isEmpty(nextProps.locationHierarchy) && nextProps.locationHierarchy !== prevState.locationHierarchy) {

      // Get all locations of type "building"
      let newHierarchy = JSON.parse(JSON.stringify(nextProps.locationHierarchy));

      let topLocation = { id: "*", children: newHierarchy, name: "" };

      // Get all location from topLocation.children with type building
      const regions = [];
      const buildings = [];
      const getLocations = (location, parentRegion) => {

        // If location is a building, add it to the list
        if (location.id !== "*" && location.type === "building") {
          const region = regions.find(region => region.id === parentRegion.id);
          if (region) {
            region.children.push(location); 
          }
          else {
            regions.push({ id: parentRegion.id, name: parentRegion.name, children: [location] });
          }

          buildings.push({ ...location }); //, name: breadcrumbs + (breadcrumbs !== "" ? " - " : "") + location.name });
        }

        if (location.children) {
          let newParentRegion = parentRegion;
          if (location.type === "region") {
            newParentRegion = location;
          }
          location.children.forEach(child => getLocations(child, newParentRegion));
        }
      }
      getLocations(topLocation, null);

      // Sort regions by name and buildings by name
      regions.sort((a, b) => a.name.localeCompare(b.name, "nb-NO"));
      regions.forEach(region => region.children.sort((a, b) => a.name.localeCompare(b.name, "nb-NO")));

      // Add all buildings as the first child of the region
      regions.forEach(region => {
        if (region.children.length > 1) {
          region.children.unshift({ id: region.id, name: "All buildings", type: "region" });
        }
      });

      // Sort buildings by favorite and name
      // buildings.sort((a, b) => {
      //   const norway = "nb-NO";
      //   const aFav = prevState.favorited[a.id] ? true : false;
      //   const bFav = prevState.favorited[b.id] ? true : false;
      //   if (aFav && !bFav) {
      //     return -1;
      //   }
      //   if (!aFav && bFav) {
      //     return 1;
      //   }
      //   return a.name.localeCompare(b.name, norway);
      // });

      // Query for occupancy for each building
      if (get(nextProps, "company.dashboardFeatures.showMeetingRooms", false) ||
        get(nextProps, "company.dashboardFeatures.showWorkplace", false)) {
        nextProps.getOccupancyMetrics(buildings.map(b => b.id));
      }

      return { regions, buildings, locationHierarchy: nextProps.locationHierarchy };
    }

    return null;
  }

  componentDidMount() {
    document.title = `BLDNG.ai - Home`;
    // console.log("Home.FrontPage.componentDidMount");
  }

  componentDidUpdate(prevProps, prevState) {
    // console.log("Home.Locations.componentDidUpdate prevProps", prevProps);
    // console.log("Home.Locations.componentDidUpdate this.props", this.props);
  }

  onChangeSearchType(event) {
    this.setState({ searchType: event.target.value, showOfflineSensors: false, showOfflineGateways: false, sortBy: "name", sortOrder: "asc" }, this.onFetchData);
  }

  onSearchKeyPress(event) {
    if (event.key && event.key === "Enter") {
      event.preventDefault();
      this.onFetchData();
    }
  }

  onFetchData() {
    console.log("onFetchData");
    const queryParams = {};
    if (this.state.showOfflineGateways || this.state.showOfflineSensors) {
      this.setState({ showSearchTable: true, offlineSearch: this.state.search });
    }
    else if (this.state.search !== null && this.state.search !== "") {
      queryParams.search = this.state.search;

      queryParams.offset = this.state.offset;
      queryParams.limit = this.state.limit;
      queryParams.sortBy = this.state.sortBy;
      queryParams.sortOrder = this.state.sortOrder;

      this.setState({ showSearchTable: true });

      if (this.state.searchType === "locations") {
        this.props.getLocations(queryParams);
      }
      else if (this.state.searchType === "sensors") {
        this.props.getSensors(queryParams);
      }
      else if (this.state.searchType === "gateways") {
        this.props.getGateways(queryParams);
      }
    }
    else {
      this.setState({ showSearchTable: false });
    }
  }

  onSearchChange(value) {
    this.setState({ search: value, offset: 0});
  }

  onSearchClear() {
    this.setState({ search: "", showOfflineGateways: false, showOfflineSensors: false, showSearchTable: false, offset: 0});
  }

  onChangeView(view) {
    this.setState({ showSearchTable: view !== "dashboard" });
  }

  onSortedChange(newSorted) {
    const sortBy = newSorted[0].id;
    const sortOrder = newSorted[0].desc ? "desc" : "asc";
    this.setState({ sortBy, sortOrder }, () => {
      if (!this.state.showOfflineGateways && !this.state.showOfflineSensors) {
        this.onFetchData();
      }
    });
  }

  onOffsetChange(offset) {
    this.setState({ offset }, this.onFetchData);
  }

  onLocationClick(column, row) {
    return {
      onClick: e => {
        // console.log(row);
        if (row && column.name !== 'isSelected') {

          let link = `/companies/${this.props.match.params.companyId}/locations/${row.original._id}/locations/`;

          if (e.metaKey || e.ctrlKey) {
            window.open(`${link}`);
          }
          else {
            this.props.history.push(link);
          }
        }
      }
    }
  }

  onSensorClick(column, row) {
    return {
      onClick: e => {
        // console.log(row);
        if (row && column.name !== 'isSelected') {

          let link = `/companies/${this.props.match.params.companyId}/sensors/${row.original.id}`;

          if (e.metaKey || e.ctrlKey) {
            window.open(`${link}`);
          }
          else {
            this.props.history.push(link);
          }
        }
      }
    }
  }

  onGatewayClick(column, row) {
    return {
      onClick: e => {
        // console.log(row);
        if (row && column.name !== 'isSelected') {

          let link = `/companies/${this.props.match.params.companyId}/gateways/${row.original.id}`;

          if (e.metaKey || e.ctrlKey) {
            window.open(`${link}`);
          }
          else {
            this.props.history.push(link);
          }
        }
      }
    }
  }

  onStatusClick(column, row) {
    return {
      onClick: e => {
        if (row && row.original.title === "Inactive sensors") {
          this.setState({ showSearchTable: true, searchType: "sensors", offlineSearch: "", showOfflineSensors: true, showOfflineGateways: false });
        }
        else if (row && row.original.title === "Inactive gateways") {
          this.setState({ showSearchTable: true, searchType: "gateways", offlineSearch: "", showOfflineGateways: true, showOfflineSensors: false });
        }
      }
    }
  }

  onFilterClick(column, row) {
    return {
      onClick: e => {
        if (row) {
          let link = `/companies/${this.props.match.params.companyId}/home/dash?f=${row.original.id}`;

          if (e.metaKey || e.ctrlKey) {
            window.open(`${link}`);
          }
          else {
            this.props.history.push(link);
          }
        }
      }
    }
  }

  onBuildingClick(column, row) {
    return {
      onClick: e => {
        if (row) {
          const params = new URLSearchParams(this.props.history.location.search);
          params.set("b", row.original.id);
          this.props.history.push({ 
            pathname: this.props.history.location.pathname.substr(0, this.props.history.location.pathname.length),
            search: params.toString()
          });
          
          this.setState({ selectedBuildingId: row.original.id });
        }
      },
      onMouseOver: e => {
        if (row && row.original.id) {
          this.onHover(row.original.id);
        }
      },
      onMouseLeave: e => this.onHover(null),
      style: {
        cursor: "pointer",
        background: (
          this.state.selectedBuildingId ? (row && row.original.id === this.state.selectedBuildingId ? "rgba(0,0,0,0.05)" : null) : (row && row.index === 0 ? "rgba(0,0,0,0.05)" : null)
        )
      }
    }
  }

  onHover(id) {
    if (this.state.highlightedId !== id) {
      this.setState({ highlightedId: id });
    }
  }

  onReportClick(locationId, workplaceCategoryId) {
    const filters = [{
      lid: locationId,
      wpc: workplaceCategoryId
    }];

    // Default date range is last 30 days (minus last 2 days)
    const defaultStart = moment().subtract(1, "month").startOf("isoWeek");
    const lastAvailableDate = moment().subtract(2, "days").startOf("day");
    
    let defaultEnd = moment();
    if (defaultEnd.isAfter(lastAvailableDate)) {
      defaultEnd = lastAvailableDate.startOf("day");
    }

    const dateRanges = [{ startDate: defaultStart.toISOString(), endDate: defaultEnd.toISOString() }];
    this.props.setWorkplaceQuery(dateRanges, false, false, locationId, workplaceCategoryId, null);

    this.props.history.push(`/companies/${this.props.match.params.companyId}/reports?f=${compressToEncodedURIComponent(JSON.stringify(filters))}`);
  }

  onRemoveTagClick(tag) {
    this.setState({ showOfflineGateways: false, showOfflineSensors: false, showSearchTable: false });
  }

  onToggleExpand(locationId) {
    this.setState({ expanded: { ...this.state.expanded, [locationId]: !this.state.expanded[locationId] } });
  }

  onToggleAppUsageExpand() {
    this.setState({ appUsageExpanded: !this.state.appUsageExpanded });
  }

  onToggleFavorite(locationId) {

    this.setState({ favBuildingId: locationId });
    localStorage.setItem("dash_favorite", locationId);

    // const isFavorited = this.state.favorited[locationId];

    // let newFavorited = { ...this.state.favorited };
    // let newExpanded = { ...this.state.expanded };
    // if (isFavorited) {
    //   delete newFavorited[locationId];
    //   newExpanded[locationId] = false;
    // }
    // else {
    //   newFavorited[locationId] = true;
    //   newExpanded[locationId] = true;
    // }

    // // Sort buildings by favorited
    // const buildings = [...this.state.buildings].sort((a, b) => {
    //   const norway = "nb-NO";
    //   const aFav = newFavorited[a.id] ? true : false;
    //   const bFav = newFavorited[b.id] ? true : false;
    //   if (aFav && !bFav) {
    //     return -1;
    //   }
    //   if (!aFav && bFav) {
    //     return 1;
    //   }
    //   return a.name.localeCompare(b.name, norway);
    // });

    // this.setState({ favorited: newFavorited, expanded: newExpanded, buildings });

    // localStorage.setItem("dash_favorites", JSON.stringify(Object.keys(newFavorited)));
  }

  onChartClick(event, locationId) {
    // Set filter before navigating to dashboard
    const filter = { filter: JSON.stringify({ or: [{ and: [{ or: [{ property: "_id", operator: "eq", value: locationId, options: { includeDescendants: true } }] }] }] }) };
    this.props.setFilter(filter, locationId, this.props.history.push);
  }

  onToggleLocation(row) {
    if (this.props.selectedLocations[row.original._id] !== undefined && this.props.selectedLocations[row.original._id]) {
      this.props.deselectLocation(row.original._id);
    }
    else {
      this.props.selectLocation(row.original);
    }
  }

  onToggleAllLocations() {
    const locations = get(this.props.locations, "data.locations", []);
    const allLocationsAreSelected = !isEmpty(locations) && locations.every(location => this.props.selectedLocations[location._id] !== undefined && this.props.selectedLocations[location._id]);

    if (allLocationsAreSelected) {
      this.props.deselectLocations(locations);
    }
    else {
      this.props.selectLocations(locations.map(location => {
        return { ...location };
      }));
    }
  }

  onToggleSensor(row) {
    if (this.props.selectedSensors[row.original.id] !== undefined && this.props.selectedSensors[row.original.id]) {
      this.props.deselectSensor(row.original.id);
    }
    else {
      this.props.selectSensor(row.original);
    }
  }

  onToggleAllSensors() {
    let sensors;
    if (this.state.showOfflineSensors) {
      sensors = get(this.props.offlineSensors, "sensors", []);

      if (this.state.offlineSearch !== "") {
        sensors = sensors.filter(sensor => sensor.name && sensor.name.toLowerCase().includes(this.state.offlineSearch.toLowerCase()));
      }
    }
    else {
      sensors = get(this.props.sensors, "metricData.results", []);
    }

    const allSensorsAreSelected = !isEmpty(sensors) && sensors.every(sensor => this.props.selectedSensors[sensor.id] !== undefined && this.props.selectedSensors[sensor.id]);

    if (allSensorsAreSelected) {
      this.props.deselectSensors(sensors);
    }
    else {
      this.props.selectSensors(sensors.map(sensor => {
        return { ...sensor };
      }));
    }
  }

  onToggleGateway(row) {
    if (this.props.selectedGateways[row.original.id] !== undefined && this.props.selectedGateways[row.original.id]) {
      this.props.deselectGateway(row.original.id);
    }
    else {
      this.props.selectGateway(row.original);
    }
  }
  
  onToggleAllGateways() {
    let gateways;
    if (this.state.showOfflineGateways) {
      gateways = get(this.props.offlineGateways, "gateways", []);

      if (this.state.offlineSearch !== "") {
        gateways = gateways.filter(gateway => gateway.name && gateway.name.toLowerCase().includes(this.state.offlineSearch.toLowerCase()));
      }
    }
    else {
      gateways = get(this.props.gateways, "metricData.results", []);
    }

    const allGatewaysAreSelected = !isEmpty(gateways) && gateways.every(gateway => this.props.selectedGateways[gateway.id] !== undefined && this.props.selectedGateways[gateway.id]);

    if (allGatewaysAreSelected) {
      this.props.deselectGateways(gateways);
    }
    else {
      this.props.selectGateways(gateways.map(gateway => {
        return { ...gateway };
      }));
    }
  }

  locationTable() {

    const locations = get(this.props.locations, "data.locations", []);
    const count = get(this.props.locations, "data.count", 0);

    const allLocationsAreSelected = !isEmpty(locations) && locations.every(location => this.props.selectedLocations[location._id] !== undefined && this.props.selectedLocations[location._id]);

    return (
      <Table
        data={locations}
        sortBy={this.state.sortBy}
        sortOrder={this.state.sortOrder}
        offset={this.state.offset}
        limit={this.state.limit}
        count={count}
        onOffsetChange={this.onOffsetChange}
        onSortedChange={this.onSortedChange}
        noDataText={this.props.isLoading ? "" : "No locations found"}
        columns={[
          {
            id: "locationId",
            header: "",
            accessorKey: "_id",
            sortable: false,
            name: "isSelected",
            header: () => (
              <label className="checkboxContainer checkboxHeaderContainer" htmlFor={`editCheckbox-header`}>
                <input
                  id={`editCheckbox-header`}
                  type="checkbox"
                  className="checkbox"
                  checked={allLocationsAreSelected}
                  onChange={() => this.onToggleAllLocations()}
                  disabled={isEmpty(locations)}
                />
                <span className={isEmpty(locations) ? "disabledCheckmark" : "checkmark"} />
              </label>
            ),
            cell: ({ row }) => (
              <label className="checkboxContainer" htmlFor={`editCheckbox-${row.original._id}`}>
                <input
                  id={`editCheckbox-${row.original._id}`}
                  type="checkbox"
                  className="checkbox"
                  checked={(this.props.selectedLocations[row.original._id] !== undefined && this.props.selectedLocations[row.original._id])}
                  onChange={() => this.onToggleLocation(row)}
                />
                <span className="checkmark" />
              </label>
            ),
            width: 60
          },
          {
            header: "Name",
            accessorKey: "name",
            minWidth: 100,
            maxWidth: 500,
            cell: ({ row }) => {
              let breadcrumbs = this.props.locationBreadcrumbs[row.original._id].map(breadcrumb => breadcrumb.name);
              breadcrumbs.pop();
              breadcrumbs = breadcrumbs.join(", ");
              if (breadcrumbs) {
                return (<><span title={row.original.name}>{row.original.name}</span><span title={breadcrumbs} style={{ color: "grey", marginLeft: "10px" }}> ({breadcrumbs})</span></>)
              }
              return (<span title={row.original.name}>{row.original.name}</span>)
            }
          },
          {
            header: "Type",
            accessorKey: "type",
            minWidth: 50,
            maxWidth: 300,
            cell: ({ row }) => {
              const locationType = locationFilterTypes.find(locationType => locationType.id === row.original.type);
              const name = get(locationType, "name", row.original.type);
              return <span title={name}>{name}</span>
            }
          },
          {
            header: "Tags",
            accessorKey: "customTags",
            minWidth: 100,
            cell: ({ row }) => {
              if (row["customTags"]) {
                const norway = "nb-NO";
                return row["customTags"]
                  .map(tagId => this.props.customTags.find(tag => tag.id === tagId))
                  .filter(n => n)
                  .sort((a, b) => a.name.localeCompare(b.name, norway))
                  .map(tag => <Tag key={tag.id} text={tag.name} color={tag.colorTheme} />);
              }
              else {
                return null;
              }
            }
          },
          {
            id: "arrow",
            header: "",
            sortable: false,
            className: "pull-right",
            width: 60,
            cell: ({ row }) => (<div className="arrow" />)
          }
        ]}
        getTdProps={this.onLocationClick}
        getTrGroupProps={this.getTrProps}
        className="-row-clickable setMaxHeigth -highlight"
        loading={this.props.isLoading}
      />
    );
  }

  sensorTable() {

    let sensors;
    let count;
    if (this.state.showOfflineSensors) {
      sensors = get(this.props.offlineSensors, "sensors", []);
      count = get(this.props.offlineSensors, "count", 0);

      if (this.state.offlineSearch !== "") {
        sensors = sensors.filter(sensor => sensor.name && sensor.name.toLowerCase().includes(this.state.offlineSearch.toLowerCase()));
      }

      // Sort sensors by sortBy and sortOrder
      sensors = sensors.sort((a, b) => {
        const norway = "nb-NO";
        if (this.state.sortBy === "name") {
          return this.state.sortOrder === "asc" ? get(a, "name", "").localeCompare(get(b, "name", ""), norway) : get(b, "name", "").localeCompare(get(a, "name", ""), norway);
        }
        else if (this.state.sortBy === "model") {
          return this.state.sortOrder === "asc" ? get(a, "model", "").localeCompare(get(b, "model", "")) : get(b, "model", "").localeCompare(get(a, "model", ""));
        }
        else if (this.state.sortBy === "vendor") {
          return this.state.sortOrder === "asc" ? get(a, "vendor", "").localeCompare(get(b, "vendor", "")) : get(b, "vendor", "").localeCompare(get(a, "vendor", ""));
        }
        else if (this.state.sortBy === "gatewayDeviceId") {
          const aGateway = a.gatewayDeviceId ? a.gatewayDeviceId : "";
          const bGateway = b.gatewayDeviceId ? b.gatewayDeviceId : "";
          return this.state.sortOrder === "asc" ? aGateway.localeCompare(bGateway) : bGateway.localeCompare(aGateway);
        }
        else if (this.state.sortBy === "lastSampleAt") {
          return this.state.sortOrder === "asc" ? moment(a.lastSampleAt).diff(moment(b.lastSampleAt)) : moment(b.lastSampleAt).diff(moment(a.lastSampleAt));
        }
      });

      // Fix reload issue
      sensors = sensors.map(sensor => sensor);
    }
    else {
      sensors = get(this.props.sensors, "metricData.results", []);
      count = get(this.props.sensors, "metricData.count", 0);
    }

    const allSensorsAreSelected = !isEmpty(sensors) && sensors.every(sensor => this.props.selectedSensors[sensor.id] !== undefined && this.props.selectedSensors[sensor.id]);

    let columns = [
      {
        id: "sensorId",
        header: "",
        accessorKey: "id",
        sortable: false,
        name: "isSelected",
        header: () => (
          <label className="checkboxContainer checkboxHeaderContainer" htmlFor={`editCheckbox-header`}>
            <input
              id={`editCheckbox-header`}
              type="checkbox"
              className="checkbox"
              checked={allSensorsAreSelected}
              onChange={() => this.onToggleAllSensors()}
              disabled={isEmpty(sensors)}
            />
            <span className={isEmpty(sensors) ? "disabledCheckmark" : "checkmark"} />
          </label>
        ),
        cell: ({ row }) => (
          <label className="checkboxContainer" htmlFor={`editCheckbox-${row.original.id}`}>
            <input
              id={`editCheckbox-${row.original.id}`}
              type="checkbox"
              className="checkbox"
              checked={(this.props.selectedSensors[row.original.id] !== undefined && this.props.selectedSensors[row.original.id])}
              onChange={() => this.onToggleSensor(row)}
            />
            <span className="checkmark" />
          </label>
        ),
        width: 60
      },
      {
        header: "Name",
        accessorKey: "name",
        minWidth: 100,
        maxWidth: 1200,
        cell: ({ row }) => {
          if (row.original.locationId) {
            const breadcrumbs = this.props.locationBreadcrumbs[row.original.locationId].map(breadcrumb => breadcrumb.name).join(", ");
            return (<><span title={row.original.name}>{row.original.name}</span><span title={breadcrumbs} style={{ color: "grey", marginLeft: "10px" }}> ({breadcrumbs})</span></>)
          }
          return (<span title={row.original.name}>{row.original.name}</span>)
        }
      },
      {
        header: "Model",
        accessorKey: "model",
        minWidth: 100,
        maxWidth: 200,
        cell: ({ row }) => (<span>{row.original.model}</span>)
      },
      {
        header: "Vendor",
        accessorKey: "vendor",
        minWidth: 100,
        maxWidth: 200,
        cell: ({ row }) => (<span>{row.original.vendor}</span>)
      },
      {
        header: "Gateway",
        accessorKey: "gatewayDeviceId",
        minWidth: 100,
        maxWidth: 300,
        cell: ({ row }) => (<span>{row.original.gatewayDeviceId}</span>)
      },
      {
        id: "arrow",
        header: "",
        accessorKey: "properties",
        sortable: false,
        className: "pull-right",
        width: 60,
        cell: () => <div className="arrow" />
      }
    ];

    if (this.state.showOfflineSensors) {
      // insert lastSampleAt column at next to last position
      columns.splice(columns.length - 1, 0, {
        header: "Last sample",
        accessorKey: "lastSampleAt",
        width: 140,
        cell: ({ row }) => (<span>{row.original.lastSampleAt ? moment(row.original.lastSampleAt).format("DD/MM/YY, HH:mm") : "N/A"}</span>)
      });
    }

    return (
      <Table
        data={sensors}
        sortBy={this.state.sortBy}
        sortOrder={this.state.sortOrder}
        offset={this.state.offset}
        limit={this.state.limit}
        count={count}
        onOffsetChange={this.onOffsetChange}
        onSortedChange={this.onSortedChange}
        noDataText={this.props.isLoading ? "" : "No sensors found"}
        columns={columns}
        getTdProps={this.onSensorClick}
        getTrGroupProps={this.getTrProps}
        className="-row-clickable setMaxHeigth -highlight"
        loading={this.props.isLoading}
      />
    );
  }

  gatewayTable() {

    let gateways;
    let count;
    let limit;
    if (this.state.showOfflineGateways) {
      gateways = get(this.props.offlineGateways, "gateways", []);
      count = get(this.props.offlineGateways, "count", 0);
      limit = get(this.props.offlineGateways, "limit", 20);

      if (this.state.offlineSearch !== "") {
        gateways = gateways.filter(gateway => gateway.name && gateway.name.toLowerCase().includes(this.state.offlineSearch.toLowerCase()));
      }

      // Sort gateways by sortBy and sortOrder
      gateways = gateways.sort((a, b) => {
        const norway = "nb-NO";
        if (this.state.sortBy === "name") {
          return this.state.sortOrder === "asc" ? get(a, "name", "").localeCompare(get(b, "name", ""), norway) : get(b, "name", "").localeCompare(get(a, "name", ""), norway);
        }
        else if (this.state.sortBy === "vendor") {
          return this.state.sortOrder === "asc" ? get(a, "vendor", "").localeCompare(get(b, "vendor", "")) : get(b, "vendor", "").localeCompare(get(a, "vendor", ""));
        }
        else if (this.state.sortBy === "lastSampleAt") {
          return this.state.sortOrder === "asc" ? moment(a.lastSampleAt).diff(moment(b.lastSampleAt)) : moment(b.lastSampleAt).diff(moment(a.lastSampleAt));
        }
      });

      // Fix reload issue
      gateways = gateways.map(gateway => gateway);
    }
    else {
      gateways = get(this.props.gateways, "metricData.results", []);
      count = get(this.props.gateways, "metricData.count", 0);
      limit = get(this.props.gateways, "metricData.limit", 20);
    }

    const allGatewaysAreSelected = !isEmpty(gateways) && gateways.every(gateway => this.props.selectedGateways[gateway.id] !== undefined && this.props.selectedGateways[gateway.id]);

    let columns = [
      {
        id: "gatewayId",
        header: "",
        accessorKey: "id",
        sortable: false,
        name: "isSelected",
        // header: () => (
        //   <label className="checkboxContainer checkboxHeaderContainer" htmlFor={`editCheckbox-header`}>
        //     <input
        //       id={`editCheckbox-header`}
        //       type="checkbox"
        //       className="checkbox"
        //       checked={allGatewaysAreSelected}
        //       onChange={() => this.onToggleAllGateways()}
        //       disabled={isEmpty(gateways)}
        //     />
        //     <span className={isEmpty(gateways) ? "disabledCheckmark" : "checkmark"} />
        //   </label>
        // ),
        cell: ({ row }) => (
          <label className="checkboxContainer" htmlFor={`editCheckbox-${row.original.id}`}>
            <input
              id={`editCheckbox-${row.original.id}`}
              type="checkbox"
              className="checkbox"
              checked={(this.props.selectedGateways[row.original.id] !== undefined && this.props.selectedGateways[row.original.id])}
              onChange={() => this.onToggleGateway(row)}
            />
            <span className="checkmark" />
          </label>
        ),
        width: 60
      },
      {
        header: "Name",
        accessorKey: "name",
        cell: ({ row }) => {
          if (row.original.locationId) {
            const breadcrumbs = this.props.locationBreadcrumbs[row.original.locationId].map(breadcrumb => breadcrumb.name).join(", ");
            return (<><span title={row.original.name}>{row.original.name}</span><span title={breadcrumbs} style={{ color: "grey", marginLeft: "10px" }}> ({breadcrumbs})</span></>)
          }
          return (<span>{row.original.name ? row.original.name : row.original.deviceId}{row.original.locationId}</span>)
        }
      },
      {
        header: "Vendor",
        accessorKey: "vendor",
        cell: ({ row }) => (<span>{row.original.vendor}</span>)
      },
      {
        id: "arrow",
        header: "",
        accessorKey: "properties",
        sortable: false,
        className: "pull-right",
        width: 60,
        cell: () => <div className="arrow" />
      }
    ];

    if (this.state.showOfflineGateways) {
      // insert lastSampleAt column at next to last position
      columns.splice(columns.length - 1, 0, {
        header: "Last sample",
        accessorKey: "lastSampleAt",
        width: 140,
        cell: ({ row }) => (<span>{row.original.lastSampleAt ? moment(row.original.lastSampleAt).format("DD/MM/YY, HH:mm") : "N/A"}</span>)
      });
    }

    return (
      <Table
        data={gateways}
        page={this.state.page}
        pages={Math.ceil(count / limit)}
        sorted={[{
          id: this.state.sortBy,
          desc: this.state.sortOrder === "desc"
        }]}
        onOffsetChange={this.onOffsetChange}
        onSortedChange={this.onSortedChange}
        noDataText={this.props.isLoading ? "" : "No gateways found"}
        columns={columns}
        getTdProps={this.onGatewayClick}
        getTrGroupProps={this.getTrProps}
        className="-row-clickable setMaxHeigth -highlight"
        loading={this.props.isLoading}
      />
    );
  }

  statusTable() {

    const systemUptime = this.props.sumUptime;
    const totalSensorCount = get(this.props.offlineSensors, "totalSensorCount", 0);
    const totalGatewayCount = get(this.props.offlineGateways, "totalGatewayCount", 0);
    const offlineSensorCount = get(this.props.offlineSensors, "offlineSensorCount", 0);
    const offlineGatewayCount = get(this.props.offlineGateways, "offlineGatewayCount", 0);

    const offlineGatewayPercentage = totalGatewayCount ? (offlineGatewayCount / totalGatewayCount) : -1;
    let offlineGatewayColor = DARKGREY;
    if (offlineGatewayPercentage > 0.5) {
      offlineGatewayColor = DARKGREY; // RED;
    }
    else if (offlineGatewayPercentage < 0.1 && offlineGatewayPercentage > 0) {
      offlineGatewayColor = DARKGREY; // GREEN;
    }

    const offlineSensorPercentage = totalSensorCount ? (offlineSensorCount / totalSensorCount) : -1;
    let offlineSensorColor = DARKGREY;
    if (offlineSensorPercentage > 0.5) {
      offlineSensorColor = DARKGREY; // RED;
    }
    else if (offlineSensorPercentage < 0.1 && offlineSensorPercentage > 0) {
      offlineSensorColor = DARKGREY; // GREEN;
    }

    let rows = [
      {
        title: "System uptime (last 30 days)",
        value: systemUptime ? `${systemUptime}%` : "-",
        color: systemUptime ? (systemUptime > 90 ? GREEN : RED) : DARKGREY,
        onClick: false
      },
      {
        title: "Inactive gateways",
        value: totalGatewayCount ? `${offlineGatewayCount} of ${totalGatewayCount}` : "-",
        color: offlineGatewayColor,
        onClick: true
      },
      {
        title: "Inactive sensors",
        value: totalSensorCount ? `${offlineSensorCount} of ${totalSensorCount}` : "-",
        color: offlineSensorColor,
        onClick: true
      }
    ];

    // HARD CODE one service window
    if (moment().isSameOrBefore(moment("2022-10-28"))) {
      rows.unshift({
        title: "System service planned for 28 October",
        color: DARKGREY,
        onClick: false
      });
    }

    return (
      <Table
        data={rows}
        noDataText={""}
        columns={[
          {
            accessorKey: "value",
            sortable: false,
            width: 50,
            style: { paddingLeft: "20px", marginRight: "10px" },
            cell: ({ row }) => {
              return <div style={{ justifyContent: "center", display: "flex", alignItems: "center", height: "100%" }}>
                <RowIcon tooltip="warning" bgColor={row.original.color} icon={faExclamation} size="sm" styles={{ width: "22px", height: "22px" }} />
              </div>
            }
          },
          {
            accessorKey: "title",
            cell: ({ row }) => {
              let valueElement;
              if (!isEmpty(row.original.value)) {
                valueElement = <div style={{ textAlign: "right", display: "inline-block", whiteSpace: "nowrap" }}>{row.original.value}</div>;
              }
              return (<><div style={{ display: "inline-block", overflowWrap: "break-word" }}>{row.original.title}</div>{valueElement}</>);
            },
            width: "auto"
          },
          {
            id: "arrow",
            header: "",
            sortable: false,
            className: "pull-right",
            width: 50,
            cell: ({ row }) => (row.original.onClick && <div className="arrow" />)
          }
        ]}
        getTdProps={this.onStatusClick}
        hideHeaders
        className="-row-clickable -minimalist setMaxHeigth -highlight"
        loading={this.props.isLoading}
      />
    );
  }

  savedFilterTable() {
    return (
      <Table
        data={this.props.filters.results ?? []}
        noDataText={this.props.isLoading ? "" : "No filters found"}
        columns={[
          {
            accessorKey: "name",
            style: { paddingLeft: "20px" },
            cell: ({ row }) => {
              return <span>{row.original.name}</span>
            }
          },
          {
            id: "arrow",
            header: "",
            sortable: false,
            className: "pull-right",
            width: 50,
            cell: ({ row }) => (<div className="arrow" />)
          }
        ]}
        getTdProps={this.onFilterClick}
        hideHeaders
        className="-row-clickable -minimalist setMaxHeigth -highlight"
        loading={this.props.isLoading}
      />
    );
  }

  render() {
    const { isLoading } = this.props;
    // console.log("Home.FrontPage.state", this.state)
    // console.log("Home.FrontPage.props", this.props)

    if (isLoading) {
      return <Loader fullScreen />;
    }

    let selectedBuilding = null;
    if (this.state.selectedBuildingId && this.state.regions.length > 0) {
      // Find the region containing the selected building
      const selectedRegion = this.state.regions.find(region =>
        region.children.some(child => child.id === this.state.selectedBuildingId)
      );

      // Find the selected building
      if (selectedRegion) {
        selectedBuilding = selectedRegion.children.find(child => child.id === this.state.selectedBuildingId);
    
        if (selectedBuilding.type === "region") {
          selectedBuilding = { ...selectedBuilding, name: selectedRegion.name };
        }
      }
    }
    else if (this.state.regions.length > 0) {
      selectedBuilding = this.state.regions[0].children[0];
    }


    let content = null;
    let options = null;
    if (selectedBuilding) {
      content = (
        <>
          <h1>{selectedBuilding.name}</h1>
          <p>Activity in this building in March.</p>
          <ListCard key="collaboration-spaces" title="Collaboration spaces" showHeaderDivider buttons={[{ name: "report", icon: faChartColumn, onClick: () => this.onReportClick(selectedBuilding.id, "64d0ae4ab9716c489eaa15a5") }]}>
            <div style={{ display: "flex", justifyContent: "space-between", padding: "20px" }}>
              <HorizontalBarGraph
                data={[{ value: 0.8, description: "at peak", color: "#3d86d5" }, { value: 0.6, description: "average daily peak", color: "#1C4D82" }]}
                isLoading={this.props.isLoadingOccupancy}
              />
            </div>
          </ListCard>
          <ListCard key="supporting-spaces" title="Supporting spaces" showHeaderDivider buttons={[{ name: "report", icon: faChartColumn, onClick: () => this.onReportClick(selectedBuilding.id, "64d0ae4ab9716c489eaa15a4") }]}>
            <div style={{ display: "flex", justifyContent: "space-between", padding: "20px" }}>
              <HorizontalBarGraph
                data={[{ value: 0.63, description: "at peak", color: "#3d86d5" }, { value: 0.38, description: "average daily peak", color: "#1C4D82" }]}
                isLoading={this.props.isLoadingOccupancy}
              />
            </div>
          </ListCard>
          <ListCard key="workplaces" title="Workplaces" showHeaderDivider buttons={[{ name: "report", icon: faChartColumn, onClick: () => this.onReportClick(selectedBuilding.id, "64d0ae4ab9716c489eaa15a3") }]}>
            <div style={{ display: "flex", justifyContent: "space-between", padding: "20px" }}>
              <HorizontalBarGraph
                data={[{ value: 0.78, description: "at peak", color: "#3d86d5" }, { value: 0.7, description: "average daily peak", color: "#1C4D82" }]}
                isLoading={this.props.isLoadingOccupancy}
              />
            </div>
          </ListCard>
        </>
      );

      // Get favorite building style
      const star = this.state.favBuildingId === selectedBuilding.id ? faStar : faEmptyStar;
      const starColor = this.state.favBuildingId === selectedBuilding.id ? "#f5a623" : "#6b7073";

      options = (
        <Options>
          <OptionButton name={"lang"} icon={faLanguage} options={[{ id: "en", label: "English", onClick: this.onLanguageChanged }, { id: "nb", label: "Norsk", onClick: this.onLanguageChanged }]} selected={this.state.language} />
          <OptionButton name={"help"} icon={faCircleQuestion} />
          <OptionButton name={"set as fav"} icon={star} iconColor={starColor} onClick={() => this.onToggleFavorite(selectedBuilding.id)} />
        </Options>
      );
    }

    return (
      <OuterContainer>
        <LayoutContainer>
          <SideBarContainer>
            <div style={{ paddingTop: "30px" }} />
            {
              this.state.regions.length > 0 && this.state.regions.map(region => {

                return (
                  <div key={region.id}>
                    <h3>{region.name}</h3>
                    <ListCard>
                      <Table
                        data={region.children}
                        noDataText={""}
                        columns={[
                          {
                            accessorKey: "name",
                            style: { paddingLeft: "20px" },
                            cell: ({ row }) => {
                              return <span>{row.original.name}<span style={{ color: "#666", marginLeft: "5px" }}>{this.state.favBuildingId === row.original.id ? " (default)" : "" }</span></span>
                            }
                          }
                        ]}
                        getTdProps={this.onBuildingClick}
                        hideHeaders
                        className="-row-clickable -minimalist -no-header -highlight"
                        loading={this.props.isLoading}
                      />
                    </ListCard>
                  </div>
                );

                let graphs = [];
                // console.log("this.props.buildingGraphs", this.props.buildingGraphs);

                if (get(this.props, "company.dashboardFeatures.showMeetingRooms", false)) {
                  let meeting = get(this.props.buildingGraphs, `${location.id}.meeting`, {});

                  const meetingGraph = {
                    title: "Meeting rooms",
                    type: "meeting",
                    subtitle: moment().startOf("month").subtract(1, "month").format("MMMM")
                  };

                  // If there is data - add it to the graph
                  if (meeting.avg !== undefined) {
                    meetingGraph.avgPercentage = Math.floor(meeting.avg);
                    meetingGraph.avgPercentageChange = Math.floor(meeting.avgChange);
                    meetingGraph.peakPercentage = Math.floor(meeting.peak);
                    meetingGraph.peakPercentageChange = Math.floor(meeting.peakChange);
                  }

                  graphs.push(meetingGraph);
                }

                if (get(this.props, "company.dashboardFeatures.showWorkplace", false)) {
                  let workplace = get(this.props.buildingGraphs, `${location.id}.workplace`, {});

                  const workplaceGraph = {
                    title: "Workplace",
                    type: "workplace",
                    subtitle: moment().startOf("month").subtract(1, "month").format("MMMM")
                  };

                  // If there is data - add it to the graph
                  if (workplace.avg !== undefined) {
                    workplaceGraph.avgPercentage = Math.floor(workplace.avg);
                    workplaceGraph.avgPercentageChange = Math.floor(workplace.avgChange);
                    workplaceGraph.peakPercentage = Math.floor(workplace.peak);
                    workplaceGraph.peakPercentageChange = Math.floor(workplace.peakChange);
                  }

                  graphs.push(workplaceGraph);
                }
              })
            }
            <div style={{ paddingTop: "30px" }} />
            </SideBarContainer>
            <InnerContainer $showSideBar>
              { content }
              { options }
          </InnerContainer>
        </LayoutContainer>
      </OuterContainer>
    );
  }
}

function mapStateToProps(state) {
  return {
    company: state.auth.selectedCompany,
    locations: state.locations,
    locationHierarchy: state.locations.hierarchy,
    locationBreadcrumbs: state.locations.breadcrumbs,
    buildingGraphs: state.occupancy.buildings,
    sensors: state.sensors,
    gateways: state.gateways,
    customTags: state.customTags.list,
    offlineSensors: state.offlineSensors.data,
    offlineGateways: state.offlineGateways.data,
    filters: state.filters.data,
    dataLoadingStatus: state.dashboards.dataLoadingStatus,
    isLoadingOccupancy: state.loading.occupancy,
    isLoading: state.loading.locations,
    sumUptime: state.uptime.sumUptime,
    appUsage: state.appUsage.usage,
    selectedLocations: state.selected.locations,
    selectedSensors: state.selected.sensors,
    selectedGateways: state.selected.gateways,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getOfflineSensors: dashboardActions.getOfflineSensors,
    getOfflineGateways: dashboardActions.getOfflineGateways,
    getFilters: filterActions.getFilters,
    setFilter: filterActions.setFilter,
    getLocations: locationActions.getLocations,
    getSensors: sensorActions.getAllSensors,
    getGateways: gatewayActions.getAllGateways,
    getCustomTags: customTagActions.getCustomTags,
    getLocationHierarchy: locationActions.getLocationHierarchy,
    getSystemUptime: dashboardActions.getSystemUptime,
    getOccupancyMetrics: dashboardActions.getOccupancyMetrics,
    getUniqueUserLogins: dashboardActions.getUniqueUserLogins,
    selectLocation: selectedActions.selectLocation,
    deselectLocation: selectedActions.deselectLocation,
    selectLocations: selectedActions.selectLocations,
    deselectLocations: selectedActions.deselectLocations,
    selectSensor: selectedActions.selectSensor,
    deselectSensor: selectedActions.deselectSensor,
    selectSensors: selectedActions.selectSensors,
    deselectSensors: selectedActions.deselectSensors,
    selectGateway: selectedActions.selectGateway,
    deselectGateway: selectedActions.deselectGateway,
    selectGateways: selectedActions.selectGateways,
    deselectGateways: selectedActions.deselectGateways,
    setWorkplaceQuery: reportActions.setWorkplaceQuery,
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(OldFrontPage);

const OuterContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 65px);
  width: 100%;
  background-color: #f6f6f6;

  h1, h2, h3, h4, h5, h6, p, ol, li {
    margin-top: 0;
  }

  h1 {
    margin-bottom: 20px;
  }

  h2, h3, h4, h5, h6 {
    margin-bottom: 15px;
  }

  p, ol {
    margin-bottom: 20px;
    font-size: 16px;
  }
`;

const LayoutContainer = styled.div`
  display: inline-flex;
  height: 100%;
  width: 100%;
  background-color: #f6f6f6;
`;

const SideBarContainer = styled.div`
  position: relative;
  width: 345px;
  max-height: 100%;
  height: 100%;
  overflow: auto;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
`;

const InnerContainer = styled.div`
  position: relative;
  width: ${props => props.$showSideBar ? "calc(100% - 345px)" : "100%"};
  max-height: 100%;
  height: 100%;
  overflow: auto;

  padding-left: ${props => props.$mobile ? "20px" : "40px"};
  padding-right: ${props => props.$mobile ? "20px" : "135px"}; // 95 is from the menu bar and 40 is from the padding
  padding-top: 30px; // 40px
  padding-bottom: 40px;
  box-sizing: border-box;
`;

const Options = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 55px;
  min-width: 55px;
  height: 100%;
  cursor: pointer;
  z-index: 2;
  padding-right: 20px;
  padding-left: 20px;
  cursor: default;
`;