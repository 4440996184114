import React from "react";
import { Switch, Route } from "react-router-dom";
import HomeContainer from "./container";
import NotFound from "../NotFound";
import FrontPage from "./frontPage";
import OldFrontPage from "./frontPageOld";
import WidgetSearch from "./widgetSearch";

const HomeRouter = () => (
  <Switch>
    <Route path="/companies/:companyId/home/dash" children={(props) => <HomeContainer {...props} />} />
    <Route path="/companies/:companyId/home/search" children={(props) => <WidgetSearch {...props} />} />
    <Route path="/companies/:companyId/home" children={(props) => <FrontPage {...props} />} />
    <Route component={NotFound} />
  </Switch>
);

export default HomeRouter;
