import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Popup, PopupAnchor } from "ui";
import styled from "styled-components";

const OptionButton = ({ name, icon, options, onClick }) => {

  const [isPopupVisible, setPopupVisible] = React.useState(false);
  const optionButtonRef = React.useRef(null);
  const popupRef = React.useRef(null);

  const hidePopup = (e) => {
    if (popupRef.current && !popupRef.current.contains(e.target)) {
      setPopupVisible(false);
    }
  };

  const onFilterOptionsClick = () => {
    setPopupVisible(!isPopupVisible);
  };

  const handleScroll = () => {
    setPopupVisible(false); // Hide the popup on scroll
  };

  React.useEffect(() => {
    if (isPopupVisible) {
      // Add event listener when the popup is shown
      document.addEventListener('mousedown', hidePopup);
      window.addEventListener('scroll', handleScroll, true); // Use capture mode
    }
  
    // Cleanup function to remove the event listeners
    return () => {
      document.removeEventListener('mousedown', hidePopup);
      window.removeEventListener('scroll', handleScroll, true);
    };
  }, [isPopupVisible]); // Dependency array includes isPopupVisible to re-run the effect when it changes
  
  // Get button rect
  let optionButtonRect = null;
  if (optionButtonRef.current) {
    optionButtonRect = optionButtonRef.current.getBoundingClientRect();
  }

  let popup;
  if (options) {
    popup = (
      <Popup ref={popupRef} isVisible={isPopupVisible} sourceRect={optionButtonRect} popupAnchor={PopupAnchor.TOP_RIGHT}>
        <PopupContent>
          { options.map((option, index) => (
            <PopupItem key={index} onClick={() => option.onClick(option.id)}>
              { option.icon && <FontAwesomeIcon icon={option.icon} style={{ marginRight: "10px" }} /> }
              { option.label && <span>{option.label}</span> }
            </PopupItem>
          ))}
        </PopupContent>
      </Popup>
    );
  }

  return (
    <Button ref={optionButtonRef} onClick={onClick ? onClick : onFilterOptionsClick} title={name}>
      <FontAwesomeIcon className="icon" icon={icon} color={"#333"} size="2xl" />
      { popup }
    </Button>
  );
}

export default OptionButton;

const Button = styled.div`
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 5px;
  border-width: 1px;
  border-style: solid;
  border-color: #ddd;
  cursor: pointer;

  &:hover .icon {
    color: #000; // Change #yourDesiredHoverColor to the color you want
  }
`;

const PopupContent = styled.div`
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  border-radius: 6px;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(221, 221, 221);

  overflow: hidden;
`;

const PopupItem = styled.div`
  cursor: pointer;
  padding: 10px 15px;

  &:hover {
    background-color: #eee;
  }
`;