import React, { useState, useEffect } from "react";
import styled from "styled-components";
import PercentageText from "./percentageText";
import AnimatedBar from "./animatedBar";
import { WidgetValueType } from "./types";

interface ValueListGraphProps {
  data: any[];
  valueType: WidgetValueType;
  max?: number;
  showMaxLine?: boolean;
}

const ValueListGraph: React.FC<ValueListGraphProps> = ({ data, valueType, max, showMaxLine }) => {

  const maxValue = Math.max(...data.map(item => item.value));

  const rows = data.map((item, index) => {
    
    let valueInPercentage = null;
    let value = null;
    let valueBoxWidth = 0;
    switch (valueType) {
      case WidgetValueType.Percentage:
        valueInPercentage = Math.round(item.value * 100 * 100) / 100;
        value = valueInPercentage;
        valueBoxWidth = 50;
        break;
      case WidgetValueType.Count:
        if (max) {
          valueInPercentage = Math.round((item.value / max) * 100 * 100) / 100;
        }
        else {
          valueInPercentage = maxValue > 0 ? (Math.round((item.value / maxValue) * 100 * 100) / 100) : 0;
        }
        value = item.value;

        if (maxValue > 999) {
          valueBoxWidth = 60;
        }
        else {
          valueBoxWidth = 50;
        }

        break;
      case WidgetValueType.Minutes:
        valueInPercentage = maxValue > 0 ? (Math.round((item.value / maxValue) * 100 * 100) / 100) : 0;
        value = item.value;
        valueBoxWidth = 60;
        break;
      default:
        break;
    }
    
    if (valueInPercentage === null || valueInPercentage < 0) {
      return null;
    }

    return (
      <Row key={index}>
        <Name>
          {item.name}
        </Name>
        <BarContainer>
          { showMaxLine && max && <MaxLine value={80} $showWhiteLine={valueInPercentage > 100} /> }
          <AnimatedBar isVertical={false} value={(showMaxLine && max) ? 80*valueInPercentage/100 : valueInPercentage} color={item.color} />
        </BarContainer>
        <ValueBox size={valueBoxWidth}>
          <PercentageText valueType={valueType} targetValue={value} duration={1000} />
        </ValueBox>
      </Row>
    );
  });

  return (
    <Container>
      {rows}
    </Container>
  );
};

export default ValueListGraph;


const Container = styled.div`
  display: block;
  width: 100%;
  box-sizing: border-box;
  background-color: #fff;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const Name = styled.div`
  font-size: 16px;
  color: #333;
  min-width: 200px;
  white-space: pre-line;
  flex: 1;
`;

const BarContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-grow: 1;
  height: 100%;
  background-color: #eee;
  border-radius: 8px;
  overflow: hidden;
`;

// Dotted line at the top of the bar
const MaxLine = styled.div<{ value: number, $showWhiteLine: boolean }>`
  position: absolute;
  left: ${props => props.value}%;
  width: 1px;
  height: 100%;
  border-radius: 8px;
  border-right: 1px dashed #999;
  border-right: ${props => props.$showWhiteLine ? "1px dashed #fff" : "1px dashed #999"};
`;

const ValueBox = styled.div<{ size: number }>`
  font-size: 14px;
  font-weight: bold;
  color: #666;
  background-color: #eee;
  border-radius: 8px;
  margin-left: 8px;
  width: ${props => props.size}px;
  height: 30px;
  text-align: center;
  box-sizing: border-box;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const Description = styled.div`
  font-size: 12px;
  color: #555;
  margin-left: 8px;
  width: 60px;
  white-space: pre-line;
`;
