import axios from "axios";
import moment from "moment";
import Cookies from "universal-cookie";
import * as types from "../ApiTypes";
import * as actions from "./sharedFunction";
import { WPA_URL, COOKIE_PREFIX, MARTS_URL } from "../env";
import { getCompanyId } from "../helpers";
import { WidgetGraphType, WidgetValueType } from "../containers/Home/Widget/types"

const cookies = new Cookies();

const WPAColors = {
  OVERVIEW: "#111",
  COLLABORATION_ROOMS: "#17786b",
  SUPPORTING_ROOMS: "#9C315E",
  ACTIVITY_ZONE: "#1A8793",
  PEOPLE_COUNT: "#14628F",
  WORKSTATIONS: "#613286"
};

const WPAColorsDark = {
  OVERVIEW: "#111",
  COLLABORATION_ROOMS: "#344b47",
  SUPPORTING_ROOMS: "#67213E",
  ACTIVITY_ZONE: "#156F79",
  PEOPLE_COUNT: "#0F496B",
  WORKSTATIONS: "#41215A"
};

export const getWidgetProfile = (companyId) => async (dispatch) => {
  // dispatch(actions.requestData(types.GET_WIDGET_PROFILE, { companyId }));

  const wpaUrl = await WPA_URL();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  const startDate = moment.utc().subtract(1, "month").startOf("month");
  const endDate = moment.utc().subtract(1, "month").endOf("month");
  const lastMonth = moment.utc().subtract(1, "month").startOf("month");

  let profile = {};
  if (companyId === "5bd6f1eaf59309f2e2198fe9") {
    profile = {
      overview: {
        widgets: [
          {
            id: "peopleCountIntraday",
            customTagId: "66fa4687001d7ca1bef3b00a",
            wpaCategoryNames: ["People count"],
            type: WidgetGraphType.VerticalBar,
            valueType: WidgetValueType.Count,
            dateKey: "hour",
            title: `Daily people count`,
            subtitle: lastMonth.format("MMMM YYYY"),
            description: `The highest number of people by hour of day in $locationName.`,
            dataLayout: [
              { property: "peakPeak", hour: 7, color: WPAColors.PEOPLE_COUNT, description: "07-08" },
              { property: "peakPeak", hour: 8, color: WPAColors.PEOPLE_COUNT, description: "" },
              { property: "peakPeak", hour: 9, color: WPAColors.PEOPLE_COUNT, description: "" },
              { property: "peakPeak", hour: 10, color: WPAColors.PEOPLE_COUNT, description: "" },
              { property: "peakPeak", hour: 11, color: WPAColors.PEOPLE_COUNT, description: "" },
              { property: "peakPeak", hour: 12, color: WPAColors.PEOPLE_COUNT, description: "" },
              { property: "peakPeak", hour: 13, color: WPAColors.PEOPLE_COUNT, description: "" },
              { property: "peakPeak", hour: 14, color: WPAColors.PEOPLE_COUNT, description: "" },
              { property: "peakPeak", hour: 15, color: WPAColors.PEOPLE_COUNT, description: "" },
              { property: "peakPeak", hour: 16, color: WPAColors.PEOPLE_COUNT, description: "16-17" }
            ]
          },
          {
            id: "utilizationMonthTopLocations",
            wpaCategoryId: "643f8a02edfd30f1428cb985",
            wpaCategoryNames: ["CollaborationSpace", "Meeting Rooms"],
            type: WidgetGraphType.DynamicValueList,
            valueType: WidgetValueType.Percentage,
            dateKey: "datetime",
            title: `Most popular rooms`,
            subtitle: lastMonth.format("MMMM YYYY"),
            description: `The rooms with the highest occupancy in $locationName.`,
            dataLayout: { property: "avgOccupancy", datetime: lastMonth.toISOString(), color: WPAColors.COLLABORATION_ROOMS, nameProperty: "locationName" }
          }
        ]
      },
      peopleCount: {
        customTagId: "66fa4687001d7ca1bef3b00a"
      }
    }
  }
  else if (companyId === "5bd6f1eaf59309f2e2198fe8") {
    profile = {
      overview: {
        widgets: [
          {
            id: "peopleCountIntraday",
            customTagId: "65375e826433c5813d2d4b02",
            wpaCategoryNames: ["People count"],
            type: WidgetGraphType.VerticalBar,
            valueType: WidgetValueType.Count,
            dateKey: "hour",
            title: `Daily people count`,
            subtitle: lastMonth.format("MMMM YYYY"),
            description: `The highest number of people by hour of day in $locationName.`,
            dataLayout: [
              { property: "peakPeak", hour: 7, color: WPAColors.PEOPLE_COUNT, description: "07-08" },
              { property: "peakPeak", hour: 8, color: WPAColors.PEOPLE_COUNT, description: "" },
              { property: "peakPeak", hour: 9, color: WPAColors.PEOPLE_COUNT, description: "" },
              { property: "peakPeak", hour: 10, color: WPAColors.PEOPLE_COUNT, description: "" },
              { property: "peakPeak", hour: 11, color: WPAColors.PEOPLE_COUNT, description: "" },
              { property: "peakPeak", hour: 12, color: WPAColors.PEOPLE_COUNT, description: "" },
              { property: "peakPeak", hour: 13, color: WPAColors.PEOPLE_COUNT, description: "" },
              { property: "peakPeak", hour: 14, color: WPAColors.PEOPLE_COUNT, description: "" },
              { property: "peakPeak", hour: 15, color: WPAColors.PEOPLE_COUNT, description: "" },
              { property: "peakPeak", hour: 16, color: WPAColors.PEOPLE_COUNT, description: "16-17" }
            ]
          },
          {
            id: "utilizationMonthTopLocations",
            wpaCategoryId: "643f8a02edfd30f1428cb981",
            wpaCategoryNames: ["CollaborationSpace"],
            type: WidgetGraphType.DynamicValueList,
            valueType: WidgetValueType.Percentage,
            dateKey: "datetime",
            title: `Most popular rooms`,
            subtitle: lastMonth.format("MMMM YYYY"),
            description: `The rooms with the highest occupancy in $locationName.`,
            dataLayout: { property: "avgOccupancy", datetime: lastMonth.toISOString(), color: WPAColors.COLLABORATION_ROOMS, nameProperty: "locationName" }
          }
        ]
      },
      peopleCount: {
        customTagId: "65375e826433c5813d2d4b02"
      }
    }
  }
  else if (companyId === "5bd6f1eaf59309f2e2198fe6") {
    profile = {
      overview: {
        widgets: [
          {
            id: "peopleCountIntraday",
            customTagId: "666959ebffded88140b98bf6",
            wpaCategoryNames: ["People count"],
            type: WidgetGraphType.VerticalBar,
            valueType: WidgetValueType.Count,
            dateKey: "hour",
            title: `Daily people count`,
            subtitle: lastMonth.format("MMMM YYYY"),
            description: `The highest number of people by hour of day in $locationName.`,
            dataLayout: [
              { property: "peakPeak", hour: 7, color: WPAColors.PEOPLE_COUNT, description: "07-08" },
              { property: "peakPeak", hour: 8, color: WPAColors.PEOPLE_COUNT, description: "" },
              { property: "peakPeak", hour: 9, color: WPAColors.PEOPLE_COUNT, description: "" },
              { property: "peakPeak", hour: 10, color: WPAColors.PEOPLE_COUNT, description: "" },
              { property: "peakPeak", hour: 11, color: WPAColors.PEOPLE_COUNT, description: "" },
              { property: "peakPeak", hour: 12, color: WPAColors.PEOPLE_COUNT, description: "" },
              { property: "peakPeak", hour: 13, color: WPAColors.PEOPLE_COUNT, description: "" },
              { property: "peakPeak", hour: 14, color: WPAColors.PEOPLE_COUNT, description: "" },
              { property: "peakPeak", hour: 15, color: WPAColors.PEOPLE_COUNT, description: "" },
              { property: "peakPeak", hour: 16, color: WPAColors.PEOPLE_COUNT, description: "16-17" }
            ]
          },
          {
            id: "utilizationMonthTopLocations",
            wpaCategoryId: "643f8a02edfd30f1428cb979",
            wpaCategoryNames: ["CollaborationSpace"],
            type: WidgetGraphType.DynamicValueList,
            valueType: WidgetValueType.Percentage,
            dateKey: "datetime",
            title: `Most popular rooms`,
            subtitle: lastMonth.format("MMMM YYYY"),
            description: `The rooms with the highest occupancy in $locationName.`,
            dataLayout: { property: "avgOccupancy", datetime: lastMonth.toISOString(), color: WPAColors.COLLABORATION_ROOMS, nameProperty: "locationName" }
          }
        ]
      },
      peopleCount: {
        customTagId: "666959ebffded88140b98bf6"
      }
    }
  }
  else if (companyId === "5c6e52c783bd8000084972da") {
    profile = {
      overview: {
        widgets: [
          {
            id: "utilizationMonthTopLocations",
            wpaCategoryId: "6423fc975d0a7a525fad3075",
            wpaCategoryNames: ["CollaborationSpace", "Meeting Rooms"],
            type: WidgetGraphType.DynamicValueList,
            valueType: WidgetValueType.Percentage,
            dateKey: "datetime",
            title: `Most popular rooms`,
            subtitle: lastMonth.format("MMMM YYYY"),
            description: `The rooms with the highest occupancy in $locationName.`,
            dataLayout: { property: "avgOccupancy", datetime: lastMonth.toISOString(), color: WPAColors.COLLABORATION_ROOMS, nameProperty: "locationName" }
          },
          {
            id: "peopleCountIntraday",
            customTagId: "660be2dfdc45295fc6a93a85",
            wpaCategoryNames: ["People count"],
            type: WidgetGraphType.VerticalBar,
            valueType: WidgetValueType.Count,
            dateKey: "hour",
            title: `Daily people count`,
            subtitle: lastMonth.format("MMMM YYYY"),
            description: `The highest number of people by hour of day in $locationName.`,
            dataLayout: [
              { property: "peakPeak", hour: 7, color: WPAColors.PEOPLE_COUNT, description: "07-08" },
              { property: "peakPeak", hour: 8, color: WPAColors.PEOPLE_COUNT, description: "" },
              { property: "peakPeak", hour: 9, color: WPAColors.PEOPLE_COUNT, description: "" },
              { property: "peakPeak", hour: 10, color: WPAColors.PEOPLE_COUNT, description: "" },
              { property: "peakPeak", hour: 11, color: WPAColors.PEOPLE_COUNT, description: "" },
              { property: "peakPeak", hour: 12, color: WPAColors.PEOPLE_COUNT, description: "" },
              { property: "peakPeak", hour: 13, color: WPAColors.PEOPLE_COUNT, description: "" },
              { property: "peakPeak", hour: 14, color: WPAColors.PEOPLE_COUNT, description: "" },
              { property: "peakPeak", hour: 15, color: WPAColors.PEOPLE_COUNT, description: "" },
              { property: "peakPeak", hour: 16, color: WPAColors.PEOPLE_COUNT, description: "16-17" }
            ]
          }
        ],
      },
      peopleCount: {
        customTagId: "660be2dfdc45295fc6a93a85"
      }
    }
  }

  dispatch(actions.receiveData(profile, types.GET_WIDGET_PROFILE, { companyId }));

  // axios.get(`${wpaUrl}companies/${companyId}/widget-profile`, await actions.getAxiosConfig())
  //   .then((response) => {
  //     dispatch(actions.receiveData(response.data, types.GET_WIDGET_PROFILE, { companyId }));
  //   })
  //   .catch((error) => {
  //     if (error.response && error.response.status === 401) {
  //       actions.sessionTokenExpired(cookiePrefix, accessToken);
  //     } else {
  //       dispatch(actions.receiveError(error, types.GET_WIDGET_PROFILE, { companyId }));
  //     }
  //   });

}

export const getFullyUtilizedMinutes = (locationId, wpaCategoryId = null, customTagId = null) => async (dispatch) => {
  dispatch(actions.requestData(types.GET_FULLY_UTILIZED_MINUTES_WIDGET, { locationId, wpaCategoryId, customTagId }));

  const wpaUrl = await WPA_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  const queryParams = {
    dateTrunc: "month",
    dateRanges: JSON.stringify([
      {
        startDate: moment.utc().subtract(2, "month").startOf("month").format("YYYY-MM-DD"),
        endDate: moment.utc().subtract(1, "month").endOf("month").format("YYYY-MM-DD")
      }
    ]),
    includeHolidays: false,
    includeWeekends: false,
  };

  let url = `${wpaUrl}companies/${companyId}/locations/${locationId}/fully-utilized-minutes/workplace-categories`;
  
  if (wpaCategoryId) {
    url += `/${wpaCategoryId}`;
  }

  if (customTagId) {
    url += `/custom-tags/${customTagId}`;
  }


  axios.get(url, await actions.getAxiosConfig(queryParams))
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.GET_FULLY_UTILIZED_MINUTES_WIDGET, { locationId, wpaCategoryId, customTagId }));
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.GET_FULLY_UTILIZED_MINUTES_WIDGET, { locationId, wpaCategoryId, customTagId }));
      }
    });
}

export const getNotUtilizedMinutes = (locationId, wpaCategoryId = null, customTagId = null) => async (dispatch) => {
  dispatch(actions.requestData(types.GET_NOT_UTILIZED_MINUTES_WIDGET, { locationId, wpaCategoryId, customTagId }));

  const wpaUrl = await WPA_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  const queryParams = {
    dateTrunc: "month",
    dateRanges: JSON.stringify([
      {
        startDate: moment.utc().subtract(2, "month").startOf("month").format("YYYY-MM-DD"),
        endDate: moment.utc().subtract(1, "month").endOf("month").format("YYYY-MM-DD")
      }
    ]),
    includeHolidays: false,
    includeWeekends: false,
  };

  let url = `${wpaUrl}companies/${companyId}/locations/${locationId}/not-utilized-minutes/workplace-categories`;
  
  if (wpaCategoryId) {
    url += `/${wpaCategoryId}`;
  }

  if (customTagId) {
    url += `/custom-tags/${customTagId}`;
  }

  axios.get(url, await actions.getAxiosConfig(queryParams))
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.GET_NOT_UTILIZED_MINUTES_WIDGET, { locationId, wpaCategoryId, customTagId }));
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.GET_NOT_UTILIZED_MINUTES_WIDGET, { locationId, wpaCategoryId, customTagId }));
      }
    });
}

export const getAtCapacityMinutes = (locationId, customTagId = null) => async (dispatch) => {
  dispatch(actions.requestData(types.GET_AT_CAPACITY_MINUTES_WIDGET, { locationId, customTagId }));

  const wpaUrl = await WPA_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  const queryParams = {
    dateTrunc: "month",
    dateRanges: JSON.stringify([
      {
        startDate: moment.utc().subtract(2, "month").startOf("month").format("YYYY-MM-DD"),
        endDate: moment.utc().subtract(1, "month").endOf("month").format("YYYY-MM-DD")
      }
    ]),
    includeHolidays: false,
    includeWeekends: false,
  };

  let url;
  if (customTagId && customTagId !== "all") {
    url = `${wpaUrl}companies/${companyId}/locations/${locationId}/custom-tags/${customTagId}/people-count-minutes-at-capacity`;
  }
  else {
    url = `${wpaUrl}companies/${companyId}/locations/${locationId}/people-count-minutes-at-capacity`;
  }

  axios.get(url, await actions.getAxiosConfig(queryParams))
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.GET_AT_CAPACITY_MINUTES_WIDGET, { locationId, customTagId }));
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.GET_AT_CAPACITY_MINUTES_WIDGET, { locationId, customTagId }));
      }
    });
}

export const getNeverUtilized = (locationId, wpaCategoryId = null, customTagId = null) => async (dispatch) => {
  dispatch(actions.requestData(types.GET_NEVER_UTILIZED_WIDGET, { locationId, wpaCategoryId, customTagId }));

  const wpaUrl = await WPA_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  const queryParams = {
    dateTrunc: "month",
    dateRanges: JSON.stringify([
      {
        startDate: moment.utc().subtract(2, "month").startOf("month").format("YYYY-MM-DD"),
        endDate: moment.utc().subtract(1, "month").endOf("month").format("YYYY-MM-DD")
      }
    ]),
    includeHolidays: false,
    includeWeekends: false,
  };

  let url = `${wpaUrl}companies/${companyId}/locations/${locationId}/never-utilized/workplace-categories`;

  if (wpaCategoryId) {
    url += `/${wpaCategoryId}`;
  }

  axios.get(url, await actions.getAxiosConfig(queryParams))
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.GET_NEVER_UTILIZED_WIDGET, { locationId, wpaCategoryId, customTagId }));
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.GET_NEVER_UTILIZED_WIDGET, { locationId, wpaCategoryId, customTagId }));
      }
    });
}

export const getUtilizationIntraday = (locationId, wpaCategoryId = null, customTagId = null) => async (dispatch) => {
  dispatch(actions.requestData(types.GET_UTILIZATION_INTRADAY_WIDGET, { locationId, wpaCategoryId, customTagId }));

  const wpaUrl = await WPA_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  const queryParams = {
    dateRanges: JSON.stringify([
      {
        startDate: moment.utc().subtract(1, "month").startOf("month").format("YYYY-MM-DD"),
        endDate: moment.utc().subtract(1, "month").endOf("month").format("YYYY-MM-DD")
      }
    ]),
    includeHolidays: false,
    includeWeekends: false,
  };

  let url = `${wpaUrl}companies/${companyId}/locations/${locationId}/utilization-intra-day/workplace-categories`;

  if (wpaCategoryId) {
    url += `/${wpaCategoryId}`;
  }

  if (customTagId) {
    url += `/custom-tags/${customTagId}`;
  }

  axios.get(url, await actions.getAxiosConfig(queryParams))
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.GET_UTILIZATION_INTRADAY_WIDGET, { locationId, wpaCategoryId, customTagId }));
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.GET_UTILIZATION_INTRADAY_WIDGET, { locationId, wpaCategoryId, customTagId }));
      }
    });
}

export const getUtilizationDay = (locationId, wpaCategoryId = null, customTagId = null) => async (dispatch) => {
  dispatch(actions.requestData(types.GET_UTILIZATION_DAY_WIDGET, { locationId, wpaCategoryId, customTagId }));

  const wpaUrl = await WPA_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  const queryParams = {
    // dateTrunc: "month",
    dateRanges: JSON.stringify([
      {
        startDate: moment.utc().subtract(1, "month").startOf("month").format("YYYY-MM-DD"),
        endDate: moment.utc().subtract(1, "month").endOf("month").format("YYYY-MM-DD")
      }
    ]),
    includeHolidays: true,
    includeWeekends: true,
  };

  let url = `${wpaUrl}companies/${companyId}/locations/${locationId}/utilization/workplace-categories`;

  if (wpaCategoryId) {
    url += `/${wpaCategoryId}`;
  }

  if (customTagId) {
    url += `/custom-tags/${customTagId}`;
  }

  axios.get(url, await actions.getAxiosConfig(queryParams))
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.GET_UTILIZATION_DAY_WIDGET, { locationId, wpaCategoryId, customTagId }));
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.GET_UTILIZATION_DAY_WIDGET, { locationId, wpaCategoryId, customTagId }));
      }
    });
}

export const getUtilizationWeekday = (locationId, wpaCategoryId = null, customTagId = null) => async (dispatch) => {
  dispatch(actions.requestData(types.GET_UTILIZATION_WEEKDAY_WIDGET, { locationId, wpaCategoryId, customTagId }));

  const wpaUrl = await WPA_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  const queryParams = {
    dateTrunc: "weekday",
    dateRanges: JSON.stringify([
      {
        startDate: moment.utc().subtract(1, "month").startOf("month").format("YYYY-MM-DD"),
        endDate: moment.utc().subtract(1, "month").endOf("month").format("YYYY-MM-DD")
      }
    ]),
    includeHolidays: false,
    includeWeekends: false,
  };

  let url = `${wpaUrl}companies/${companyId}/locations/${locationId}/utilization/workplace-categories`;

  if (wpaCategoryId) {
    url += `/${wpaCategoryId}`;
  }

  if (customTagId) {
    url += `/custom-tags/${customTagId}`;
  }

  axios.get(url, await actions.getAxiosConfig(queryParams))
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.GET_UTILIZATION_WEEKDAY_WIDGET, { locationId, wpaCategoryId, customTagId }));
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.GET_UTILIZATION_WEEKDAY_WIDGET, { locationId, wpaCategoryId, customTagId }));
      }
    });
}

export const getUtilizationMonth = (locationId, wpaCategoryId = null, customTagId = null) => async (dispatch) => {
  dispatch(actions.requestData(types.GET_UTILIZATION_MONTH_WIDGET, { locationId, wpaCategoryId, customTagId }));

  const wpaUrl = await WPA_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  const queryParams = {
    dateTrunc: "month",
    dateRanges: JSON.stringify([
      {
        startDate: moment.utc().subtract(1, "month").startOf("month").format("YYYY-MM-DD"),
        endDate: moment.utc().subtract(1, "month").endOf("month").format("YYYY-MM-DD")
      }
    ]),
    includeHolidays: false,
    includeWeekends: false,
  };

  let url = `${wpaUrl}companies/${companyId}/locations/${locationId}/utilization/workplace-categories`;

  if (wpaCategoryId) {
    url += `/${wpaCategoryId}`;
  }

  if (customTagId) {
    url += `/custom-tags/${customTagId}`;
  }

  axios.get(url, await actions.getAxiosConfig(queryParams))
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.GET_UTILIZATION_MONTH_WIDGET, { locationId, wpaCategoryId, customTagId }));
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.GET_UTILIZATION_MONTH_WIDGET, { locationId, wpaCategoryId, customTagId }));
      }
    });
}

export const getUtilizationMonthlyDistribution = (locationId, wpaCategoryId = null, customTagId = null) => async (dispatch) => {
  dispatch(actions.requestData(types.GET_UTILIZATION_DISTRIBUTION_MONTH_WIDGET, { locationId, wpaCategoryId, customTagId }));

  let startDate = moment.utc().subtract(2, "month").startOf("month");
  let endDate = moment.utc().subtract(1, "month").endOf("month");

  // dispatch(actions.receiveData({}, types.GET_UTILIZATION_DISTRIBUTION_MONTH_WIDGET, { locationId, wpaCategoryId, customTagId }));

  const martsUrl = await MARTS_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);
  const queryParams = {
    dateTrunc: "month",
    dateRanges: JSON.stringify([
      {
        startDate: startDate.format("YYYY-MM-DD"),
        endDate: endDate.format("YYYY-MM-DD")
      }
    ]),
    includeHolidays: false,
    includeWeekends: false,
  };

  let url = `${martsUrl}companies/${companyId}/locations/${locationId}/occupancy/workplace-categories`;

  if (wpaCategoryId) {
    url += `/${wpaCategoryId}`;
  }

  if (customTagId) {
    url += `/custom-tags/${customTagId}`;
  }

  axios.get(url, await actions.getAxiosConfig(queryParams))
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.GET_UTILIZATION_DISTRIBUTION_MONTH_WIDGET, { locationId, wpaCategoryId, customTagId, startDate, endDate }));
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.GET_UTILIZATION_DISTRIBUTION_MONTH_WIDGET, { locationId, wpaCategoryId, customTagId }));
      }
    });
}

// https://api.dev.bldng.ai/marts/companies/5c6e52c783bd8000084972da/locations/5c7cdb10344ca60005ee9610/usage/workplace-categories/6423fc895d0a7a525fad3074
export const getUtilizationTopLocations = (locationId, wpaCategoryId = null, customTagId = null) => async (dispatch) => {
  dispatch(actions.requestData(types.GET_UTILIZATION_TOP_LOCATIONS_WIDGET, { locationId, wpaCategoryId, customTagId }));

  const martsUrl = await MARTS_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);
  const queryParams = {
    dateTrunc: "month",
    dateRanges: JSON.stringify([
      {
        startDate: moment.utc().subtract(1, "month").startOf("month").format("YYYY-MM-DD"),
        endDate: moment.utc().subtract(1, "month").endOf("month").format("YYYY-MM-DD")
      }
    ]),
    includeHolidays: false,
    includeWeekends: false,
    limit: 5,
    sortOrder: "desc",
  };

  let url = `${martsUrl}companies/${companyId}/locations/${locationId}/usage/workplace-categories`;

  if (wpaCategoryId) {
    url += `/${wpaCategoryId}`;
  }

  if (customTagId) {
    url += `/custom-tags/${customTagId}`;
  }

  axios.get(url, await actions.getAxiosConfig(queryParams))
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.GET_UTILIZATION_TOP_LOCATIONS_WIDGET, { locationId, wpaCategoryId, customTagId }));
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.GET_UTILIZATION_TOP_LOCATIONS_WIDGET, { locationId, wpaCategoryId, customTagId }));
      }
    });
}

export const getUtilizationBottomLocations = (locationId, wpaCategoryId = null, customTagId = null) => async (dispatch) => {
  dispatch(actions.requestData(types.GET_UTILIZATION_BOTTOM_LOCATIONS_WIDGET, { locationId, wpaCategoryId, customTagId }));

  const martsUrl = await MARTS_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);
  const queryParams = {
    dateTrunc: "month",
    dateRanges: JSON.stringify([
      {
        startDate: moment.utc().subtract(1, "month").startOf("month").format("YYYY-MM-DD"),
        endDate: moment.utc().subtract(1, "month").endOf("month").format("YYYY-MM-DD")
      }
    ]),
    includeHolidays: false,
    includeWeekends: false,
    limit: 5,
    sortOrder: "asc",
  };

  let url = `${martsUrl}companies/${companyId}/locations/${locationId}/usage/workplace-categories`;

  if (wpaCategoryId) {
    url += `/${wpaCategoryId}`;
  }

  if (customTagId) {
    url += `/custom-tags/${customTagId}`;
  }

  axios.get(url, await actions.getAxiosConfig(queryParams))
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.GET_UTILIZATION_BOTTOM_LOCATIONS_WIDGET, { locationId, wpaCategoryId, customTagId }));
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.GET_UTILIZATION_BOTTOM_LOCATIONS_WIDGET, { locationId, wpaCategoryId, customTagId }));
      }
    });
}

export const getPeopleCountIntraday = (locationId, customTagId = null) => async (dispatch) => {
  dispatch(actions.requestData(types.GET_PEOPLE_COUNT_INTRADAY_WIDGET, { locationId, customTagId }));

  const wpaUrl = await WPA_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  const queryParams = {
    // dateTrunc: "intraday",
    dateRanges: JSON.stringify([
      {
        startDate: moment.utc().subtract(1, "month").startOf("month").format("YYYY-MM-DD"),
        endDate: moment.utc().subtract(1, "month").endOf("month").format("YYYY-MM-DD")
      }
    ]),
    includeHolidays: false,
    includeWeekends: false,
  };

  let url;
  if (customTagId && customTagId !== "all") {
    url = `${wpaUrl}companies/${companyId}/locations/${locationId}/custom-tags/${customTagId}/people-count-intra-day`;
  }
  else {
    url = `${wpaUrl}companies/${companyId}/locations/${locationId}/people-count-intra-day`;
  }

  axios.get(url, await actions.getAxiosConfig(queryParams))
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.GET_PEOPLE_COUNT_INTRADAY_WIDGET, { locationId, customTagId }));
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.GET_PEOPLE_COUNT_INTRADAY_WIDGET, { locationId, customTagId }));
      }
    });
}

export const getPeopleCountDay = (locationId, customTagId = null) => async (dispatch) => {
  dispatch(actions.requestData(types.GET_PEOPLE_COUNT_DAY_WIDGET, { locationId, customTagId }));

  const wpaUrl = await WPA_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  const queryParams = {
    // dateTrunc: "weekday",
    dateRanges: JSON.stringify([
      {
        startDate: moment.utc().subtract(1, "month").startOf("month").format("YYYY-MM-DD"),
        endDate: moment.utc().subtract(1, "month").endOf("month").format("YYYY-MM-DD")
      }
    ]),
    includeHolidays: true,
    includeWeekends: true,
  };

  let url;
  if (customTagId && customTagId !== "all") {
    url = `${wpaUrl}companies/${companyId}/locations/${locationId}/custom-tags/${customTagId}/people-count-summed`;
  }
  else {
    url = `${wpaUrl}companies/${companyId}/locations/${locationId}/people-count-summed`;
  }

  axios.get(url, await actions.getAxiosConfig(queryParams))
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.GET_PEOPLE_COUNT_DAY_WIDGET, { locationId, customTagId }));
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.GET_PEOPLE_COUNT_DAY_WIDGET, { locationId, customTagId }));
      }
    });
}

export const getPeopleCountWeekday = (locationId, customTagId = null) => async (dispatch) => {
  dispatch(actions.requestData(types.GET_PEOPLE_COUNT_WEEKDAY_WIDGET, { locationId, customTagId }));

  const wpaUrl = await WPA_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  const queryParams = {
    dateTrunc: "weekday",
    dateRanges: JSON.stringify([
      {
        startDate: moment.utc().subtract(1, "month").startOf("month").format("YYYY-MM-DD"),
        endDate: moment.utc().subtract(1, "month").endOf("month").format("YYYY-MM-DD")
      }
    ]),
    includeHolidays: false,
    includeWeekends: false,
  };

  let url;
  if (customTagId && customTagId !== "all") {
    url = `${wpaUrl}companies/${companyId}/locations/${locationId}/custom-tags/${customTagId}/people-count-summed`;
  }
  else {
    url = `${wpaUrl}companies/${companyId}/locations/${locationId}/people-count-summed`;
  }

  axios.get(url, await actions.getAxiosConfig(queryParams))
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.GET_PEOPLE_COUNT_WEEKDAY_WIDGET, { locationId, customTagId }));
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.GET_PEOPLE_COUNT_WEEKDAY_WIDGET, { locationId, customTagId }));
      }
    });
}

export const getPeopleCountMonth = (locationId, customTagId = null) => async (dispatch) => {
  dispatch(actions.requestData(types.GET_PEOPLE_COUNT_MONTH_WIDGET, { locationId, customTagId }));

  const wpaUrl = await WPA_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  const queryParams = {
    dateTrunc: "month",
    dateRanges: JSON.stringify([
      {
        startDate: moment.utc().subtract(1, "month").startOf("month").format("YYYY-MM-DD"),
        endDate: moment.utc().subtract(1, "month").endOf("month").format("YYYY-MM-DD")
      }
    ]),
    includeHolidays: false,
    includeWeekends: false,
  };

  let url;
  if (customTagId && customTagId !== "all") {
    url = `${wpaUrl}companies/${companyId}/locations/${locationId}/custom-tags/${customTagId}/people-count-summed`;
  }
  else {
    url = `${wpaUrl}companies/${companyId}/locations/${locationId}/people-count-summed`;
  }

  axios.get(url, await actions.getAxiosConfig(queryParams))
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.GET_PEOPLE_COUNT_MONTH_WIDGET, { locationId, customTagId }));
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.GET_PEOPLE_COUNT_MONTH_WIDGET, { locationId, customTagId }));
      }
    });
}