import { get, isEmpty } from "lodash";
import React, { Component } from "react";
import { Col, Container, Row } from "react-grid-system";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import * as filterActions from "../../actions/filters";
import * as locationActions from "../../actions/locations";
import Freetext from "../../components/Freetext";
import InputModal from "../../components/InputModal";
import Loader from "../../components/Loader";
import SearchBox from "../../components/DashboardFilterSearchBox";
import { getLocationName, getQueryFromLocationHierarchy, locationFilterTypes, removeLocationQuery } from "../../locationHelpers";
import Dashboard from "./dashboard";
import Filters from "./filters";
import Health from "./health";
import Locations from "./locations";
import SideBar from "./sideBar";
import style from "./style.module.scss";

class HomeContainer extends Component {
  
  constructor(props) {
    // console.log("HomeContainer.constructor");
    super(props);
    this.state = {
      selectedQueryIndex: -1,
      filterOpen: false,
      editFilterMode: false,
      saveFilterMode: false,
      newName: "",
      view: getInitialView(props),
      filterId: getInitialFilter(props),
      locationId: getInitialLocationId(props),
      // hasLoadedState: false
    };
    
    // this.onRunQuery = this.onRunQuery.bind(this);
    this.onAddQuery = this.onAddQuery.bind(this);
    this.onRemoveQuery = this.onRemoveQuery.bind(this);
    this.onQueryClick = this.onQueryClick.bind(this);
    this.onQueryTextChange = this.onQueryTextChange.bind(this);
    this.onCustomTagFilterChange = this.onCustomTagFilterChange.bind(this);
    this.onAddLocationTypeChange = this.onAddLocationTypeChange.bind(this);
    this.onRemoveLocationClick = this.onRemoveLocationClick.bind(this);
    this.onRemoveLocationTypeClick = this.onRemoveLocationTypeClick.bind(this);
    this.onRemoveCustomTagClick = this.onRemoveCustomTagClick.bind(this);
    this.onLocationFilterChange = this.onLocationFilterChange.bind(this);
    this.openFilter = this.openFilter.bind(this);
    this.closeFilter = this.closeFilter.bind(this);
    this.onEditFilter = this.onEditFilter.bind(this);
    this.onApplyFilter = this.onApplyFilter.bind(this);
    this.onLoadFilter = this.onLoadFilter.bind(this);
    this.onSaveFilter = this.onSaveFilter.bind(this);
    this.onChangeView = this.onChangeView.bind(this);
    this.onUploadFilter = this.onUploadFilter.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    // console.log("HomeContainer.getDerivedStateFromProps nextProps", nextProps);
    // console.log("HomeContainer.getDerivedStateFromProps prevState", prevState);

    // Load filter from URL if we have an old filterId
    if (nextProps.hasLoadedFilter) {
      const urlFilterId = getInitialFilter(nextProps);
      if (!isEmpty(urlFilterId) && nextProps.filterId !== urlFilterId) {
        // Load new filter from URL
        nextProps.loadFilter(urlFilterId);

        return {
          filterId: urlFilterId
        }
      }
      else if (!prevState.filterOpen) {
        onRunQuery(nextProps);
      }
    }
    else if (!nextProps.isLoadingFilter && !isEmpty(nextProps.locationHierarchy)) {
      if (prevState.filterId) {
        // Load filter from state
        nextProps.loadFilter(prevState.filterId);
      }
      else if (prevState.locationId) {
        // Load filter from location
        const filter = { filter: JSON.stringify({ or: [{ and: [{ or: [{ property: "_id", operator: "eq", value: prevState.locationId, options: { includeDescendants: true }}] }] }]}) };
        nextProps.setFilter(filter);
      }
      else {
        nextProps.setFilter();
      }
    }

    return null;
  }

  componentDidMount() {
    // console.log("Home.Dashboard.componentDidMount this.props", this.props);
  }

  componentDidUpdate(prevProps, prevState) {
    // console.log("Home.Dashboard.componentDidUpdate prevProps", prevProps);
    // console.log("Home.Dashboard.componentDidUpdate this.props", this.props);
  }

  onAddQuery() {

    let queries = JSON.parse(JSON.stringify(this.props.queries));
   
    // Add top locations to filter
    const locationFilter = this.props.locationHierarchy.map(location => ({ property: "_id", value: location.id, operator: "eq", options: { includeDescendants: true }}));
    queries.push({ and: [{or: locationFilter }] });
    
    this.props.updateFilterDraft(queries);

    this.setState({ selectedQueryIndex: queries.length - 1 });
  }
  
  onRemoveQuery(queryIndex) {
    if (this.props.queries.length === 1) {
      return;
    }

    const newQueryIndex = this.props.queries.length - 2;
    
    let queries = JSON.parse(JSON.stringify(this.props.queries));
    queries.splice(queryIndex, 1);
    this.props.updateFilterDraft(queries);

    this.setState({ selectedQueryIndex: newQueryIndex >= 0 ? newQueryIndex : 0 });
  }

  onQueryClick(queryIndex) {
    this.setState({ selectedQueryIndex: queryIndex, editFilterMode: true });
  }

  onQueryTextChange(queryIndex, value) {
    let queries = JSON.parse(JSON.stringify(this.props.queries));
    let queryGroup = queries[queryIndex].and;

    const nameQuery = queryGroup.find(q => q.property === "name");
    if (nameQuery) {
      nameQuery.value = value;
    }
    else {
      queryGroup.push({ property: "name", value, operator: "regex" });
    }

    this.props.updateFilterDraft(queries);
  }

  onCustomTagFilterChange(queryIndex, customTagId, include) {
    let queries = JSON.parse(JSON.stringify(this.props.queries));

    if (include) {
      queries[queryIndex].and.push({ property: "customTag", value: customTagId, operator: "eq" });
    }
    else {
      queries[queryIndex].and = queries[queryIndex].and.filter(q => q.property !== "customTag" || q.value !== customTagId);
    }

    this.props.updateFilterDraft(queries);
  }

  onRemoveCustomTagClick(queryIndex, selectedTag) {
    let queries = JSON.parse(JSON.stringify(this.props.queries));
    queries[queryIndex].and = queries[queryIndex].and.filter(q => q.property !== "customTag" || q.value !== selectedTag.id);
    
    this.props.updateFilterDraft(queries);
  }

  onAddLocationTypeChange(queryIndex, event) {
    const selectedTypeId = event.target.value;

    let queries = JSON.parse(JSON.stringify(this.props.queries));

    // If deselecting 
    if (selectedTypeId === "none") {
      queries[queryIndex].and = queries[queryIndex].and.filter(q => q.property !== "type");
    }
    else {
      let typeQuery = queries[queryIndex].and.find(q => q.property === "type");
      if (typeQuery) {
        typeQuery.value = selectedTypeId;
        typeQuery.operator = selectedTypeId.includes("*") ? "regex" : "eq";
      }
      else {
        queries[queryIndex].and.push({ property: "type", value: selectedTypeId, operator: selectedTypeId.includes("*") ? "regex" : "eq" });
      }
    }

    this.props.updateFilterDraft(queries);
  }

  onRemoveLocationClick(queryIndex, selectedLocation) {
    let queries = JSON.parse(JSON.stringify(this.props.queries));
    queries[queryIndex] = removeLocationQuery(queries[queryIndex], selectedLocation.id);

    // If filter is empty - add top locations
    // if (get(queries[queryIndex], "and", []).length === 0 || get(queries[queryIndex], "and[0].or", []).length === 0) {
    //   this.props.locationHierarchy.map(l => l.id).forEach(id => {
    //     queries[queryIndex].and[0].or.push({ property: "_id", value: id, operator: "eq", options: { includeDescendants: true }});
    //   });
    // }

    this.props.updateFilterDraft(queries);
  }

  onRemoveLocationTypeClick(queryIndex, selectedType) {
    let queries = JSON.parse(JSON.stringify(this.props.queries));
    queries[queryIndex].and = queries[queryIndex].and.filter(q => q.property !== "type");
    this.props.updateFilterDraft(queries);
  }

  onLocationFilterChange(queryIndex, hierarchy) {
    let queries = JSON.parse(JSON.stringify(this.props.queries));
    let locationQueries = getQueryFromLocationHierarchy(null, hierarchy).or;

    // Find existing location queries
    const locationsQueryGroupIndex = queries[queryIndex].and.findIndex(q => q.or);

    // If no existing location query - add new queries
    if (locationsQueryGroupIndex < 0) {
      if (locationQueries.length > 0) {
        queries[queryIndex].and.push({ or: locationQueries });
      }
    }
    else {
      if (locationQueries.length > 0) {
        queries[queryIndex].and[locationsQueryGroupIndex] = { or: locationQueries };
      }
      else {
        queries[queryIndex].and.splice(locationsQueryGroupIndex, 1);
      }
    }

    this.props.updateFilterDraft(queries);
  }

  openFilter(event) {
    if (!this.state.filterOpen) {
      this.setState({ filterOpen: true });
    }
    event.stopPropagation();
    event.preventDefault(); 
  }

  closeFilter(event) {
    this.setState(prevState => ({
      selectedQueryIndex: -1,
      filterOpen: false,
      editFilterMode: false,
      saveFilterMode: false,
      view: prevState.view === "locations" ? "locations" : "dashboard" 
    }));
    event.stopPropagation();
    event.preventDefault(); 
  }

  onEditFilter() {
    this.setState(prevState => ({ editFilterMode: !prevState.editFilterMode }));
  }

  onApplyFilter() {
    this.setState(prevState => ({ editFilterMode: !prevState.editFilterMode, selectedQueryIndex: -1, view: "dashboard" }), () => {
      onRunQuery(this.props);
    });
  }

  onLoadFilter() {
    // Navigate to filters page
    this.props.history.push(`/companies/${this.props.match.params.companyId}/filters`);
  }

  onSaveFilter() {
    this.setState({ saveFilterMode: true, selectedQueryIndex: -1 });
  }

  onUploadFilter() {
    let body = {
      name: this.state.newName ?? this.props.filterName ?? "Unknown",
      filter: JSON.stringify({ or: this.props.queries })
    }

    // Always save as new filter and redirect to filterId
    const { push } = this.props.history;
    this.props.createFilter(body, push);
    
    // if (this.props.filterId) {
    //   this.props.updateFilter(this.props.filterId, body);
    // }
    // else {
    // }

    this.setState({ saveFilterMode: false });
  }

  onChangeView(value) {
    this.setState({ view: value });

    const params = new URLSearchParams(this.props.history.location.search);
    params.set("v", value);

    // console.log("Replace url", this.props.history.location.pathname + "?" + params.toString());
    // window.history.replaceState(null, "", this.props.history.location.pathname.substr(0, this.props.history.location.pathname.length) + "?" + params.toString());

    this.props.history.push({ 
      pathname: this.props.history.location.pathname.substr(0, this.props.history.location.pathname.length),
      search: params.toString()
    });
  }

  render() {
    // console.log("Home.Container.render.state", this.state);
    // console.log("Home.Container.render.props", this.props);

    if (this.props.isAuthLoading) { //  || !this.state.hasLoadedState
      return (
        <div className={style.outerContainer}>
          <Loader />
        </div>
      );
    }

    if (!this.props.auth.hasAdminRole) {
      return (
        <Container fluid style={{ paddingLeft: "60px", paddingRight: "60px" }}>
          <Row className={style.topRow}>
            <Col>
              <Freetext header="No access" content="You don't have administration privileges for any company in BLDNG.ai." />
            </Col>
          </Row>
        </Container>
      );
    }

    if (this.props.selectedCompany === null) {
      return (
        <Container fluid style={{ paddingLeft: "60px", paddingRight: "60px" }}>
          <Row className={style.topRow}>
            <Col>
              <Freetext header="No company is selected" />
            </Col>
          </Row>
        </Container>
      );
    }

    let header;
    if (this.props.match.params.id === "root") {
      header = this.props.selectedCompany ? this.props.selectedCompany.name : "";
    }
    else {
      header = "Home";
    }

    const topLocationIds = this.props.locationHierarchy.map(location => location.id);
    let queriesForSearchBox;

    queriesForSearchBox = this.props.queries.map(q => {
      //   // If only top locations is included - hide that in filter field
      //   const topLocationFilters = q.locations.filter(l => topLocationIds.includes(l.id));
      //   const allTopLocationsIsInclude = !topLocationFilters.find(l => l.exclude);

      let locations = [];
      let customTags = [];
      let locationTypes = [];
      let names = [];

      //   if (allTopLocationsIsInclude) {
      //     locations = q.locations.filter(l => !topLocationIds.includes(l.id));
      //   }
      //   else {
      //     locations = q.locations.filter(l => !topLocationIds.includes(l.id) || (topLocationIds.includes(l.id) && l.include));
      //   }

      q.and.map(subQuery => {
        if (subQuery.or) {
          subQuery.or.map(sub2 => {
            if (sub2.and) {
              sub2.and.map(sub3 => {
                if (sub3.property === "_id") {
                  const locationName = getLocationName({ id: "root", children: this.props.locationHierarchy }, sub3.value);
                  if (locationName) {
                    locations.push({ id: sub3.value, name: locationName, include: sub3.operator === "eq" });
                  }
                }
              });
            }
            else if (sub2.property === "_id") {
              const locationName = getLocationName({ id: "root", children: this.props.locationHierarchy }, sub2.value);
              if (locationName) {
                locations.push({ id: sub2.value, name: locationName, include: sub2.operator === "eq" });
              }
            }
          });
        }
        else if (subQuery.property === "_id") {
          const locationName = getLocationName({ id: "root", children: this.props.locationHierarchy }, subQuery.value);
          if (locationName) {
            locations.push({ id: subQuery.value, name: locationName, include: subQuery.operator === "eq" });
          }
        }
        else if (subQuery.property === "customTag") {
          const tag = this.props.customTags.find(tag => tag.id === subQuery.value)
          if (tag) {
            customTags.push(tag);
          }
        }
        else if (subQuery.property === "type") {
          const type = locationFilterTypes.find(type => type.id === subQuery.value);
          if (type) {
            locationTypes.push(type);
          }
        }
        else if (subQuery.property === "name") {
          names.push(subQuery.value);
        }
      });

      return { locationTypes, customTags, locations, value: names.join(" ") }
    });

    // Check if filter has changed
    let filterHasChanged = false;
    let filterHasBeenQueried = false;
    let queries = JSON.parse(JSON.stringify(this.props.queries));
    let filter = { or: queries };
    if (JSON.stringify(filter) !== JSON.stringify(get(this.props.highlightedFilter, "filter", null))) {
      filterHasChanged = true;
    }
    if (JSON.stringify(filter) === JSON.stringify(this.props.previousFilter)) {
      filterHasBeenQueried = true;
    }

    const sideBar = (
      <SideBar
        filterOpen={this.state.filterOpen}
        editFilterMode={this.state.editFilterMode}
        openFilter={this.openFilter}
        closeFilter={this.closeFilter}
        onApplyFilter={this.onApplyFilter}
        onLoadFilter={this.onLoadFilter}
        onSaveFilter={this.onSaveFilter}
        onEditFilter={this.onEditFilter}
        filterHasChanged={filterHasChanged}
        filterHasBeenQueried={filterHasBeenQueried}
        hasSelectedFilter={!isEmpty(this.props.highlightedFilter)}
        >
        <SearchBox
          editMode={this.state.editFilterMode}
          queries={queriesForSearchBox}
          selectedIndex={this.state.selectedQueryIndex}
          onQueryClick={this.onQueryClick}
          onSearchClick={() => { onRunQuery(this.props); }}
          onQueryChange={this.onQueryTextChange}
          onRemoveCustomTagClick={this.onRemoveCustomTagClick}
          onRemoveLocationTypeClick={this.onRemoveLocationTypeClick}
          onRemoveLocationClick={this.onRemoveLocationClick}
          onAddQuery={this.onAddQuery} 
          onRemoveQuery={this.onRemoveQuery} 
          />
      </SideBar>
    );

    let page;
    if (this.state.editFilterMode) {
      page = (
        <Filters
          {...this.props}
          selectedQueryIndex={this.state.selectedQueryIndex}
          locationHierarchy={this.props.locationHierarchy}
          queries={this.props.queries} 
          ancestors={this.state.ancestors}
          onRunQuery={() => { onRunQuery(this.props);}}
          onAddQuery={this.onAddQuery}
          onRemoveQuery={this.onRemoveQuery}
          onQueryTextChange={this.onQueryTextChange}
          onCustomTagFilterChange={this.onCustomTagFilterChange}
          onAddLocationTypeChange={this.onAddLocationTypeChange}
          onRemoveLocationClick={this.onRemoveLocationClick}
          onRemoveLocationTypeClick={this.onRemoveLocationTypeClick}
          onRemoveCustomTagClick={this.onRemoveCustomTagClick}
          onLocationFilterChange={this.onLocationFilterChange}
          />
      );
    }
    else if (this.state.view === "locations") {
      page = (
        <Locations
          {...this.props}
          onChangeView={this.onChangeView}
          />
      );
    }
    else if (this.props.auth.hasSupportRole && this.state.view === "minmax") {
      page = (
        <Health
          {...this.props}
          onChangeView={this.onChangeView}
          />
      );
    }
    else {
      page = (
        <Dashboard
          {...this.props}
          filterOpen={this.state.filterOpen}
          onChangeView={this.onChangeView}
          />
      );
    }

    return (
      <>
      <div className={style.outerContainer}>
        { sideBar }
        { page }

        { (!this.props.hasSearchedLocations || this.props.isLoadingFilter || this.props.isLoadingLocations) && <Loader />}
      </div>
      { this.state.saveFilterMode && (
        <InputModal
          show={this.state.saveFilterMode}
          onHide={() => this.setState({ saveFilterMode: false })}
          title="Save filter"
          // text="Enter a name for your filter:"
          inputLabel="Filter name"
          inputValid={!isEmpty(this.state.newName)}
          inputValue={this.state.newName}
          onInputChange={(e) => this.setState({ newName: e.target.value })}
          primaryBtn={{
            text: "Save",
            onClick: this.onUploadFilter
          }}
          secondaryBtn={{
            text: "Close",
            onClick: () => this.setState({ saveFilterMode: false })
          }}
        />
      )} 
      </>
    );
  }
}

function mapStateToProps(state) {
  return { 
    auth: state.auth,
    customTags: state.customTags.list,
    selectedCompany: state.auth.selectedCompany,
    isAuthLoading: state.loading.auth,
    locationHierarchy: state.locations.hierarchy,
    isLoadingLocations: state.loading.locations,
    filterId: state.filter.id,
    filterName: state.filter.name,
    isFilterLoading: state.loading.filter,
    isLoadingFilter: state.filter.isLoadingFilter,
    hasLoadedFilter: state.filter.hasLoadedFilter,
    hasSearchedLocations: state.filter.hasSearchedLocations,
    queries: state.filter.queries,
    previousFilter: state.filter.previousFilter,
    highlightedFilter: state.filters.highlightedFilter,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    updateFilterDraft: filterActions.updateFilterDraft,
    loadFilter: filterActions.loadFilter,
    setFilter: filterActions.setFilter,
    createFilter: filterActions.createFilter,
    updateFilter: filterActions.updateFilter,
    clearHighlightedFilter: filterActions.clearHighlightedFilter,
    queryLocations: locationActions.queryLocations
   }, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HomeContainer));

function onRunQuery(props) {

  if (props.queries.length === 0 || props.queries[0].and.length === 0) {
    // console.log("Query not loaded yet", props.queries);
    return;
  }

  // Remove queries with exclude from top locations
  let queries = JSON.parse(JSON.stringify(props.queries));
  // let topLocationIds = props.locationHierarchy.map(l => l.id);

  // [{ property: "_id", value: location.id, operator: "eq", options: { includeDescendants: true }}]

  // queries.forEach(q => {
  //   q.locations = q.locations.filter(lq => !(lq.exclude && topLocationIds.includes(lq.id)));
  // });

  // Remove queries with exclude
  // queries.forEach(q => {
  //   q.locations = q.locations.filter(lq => !(lq.exclude && lq.descendants));
  // });


  // If all top locations are include - replace them with "all locations"
  // queries.forEach(q => {
  //   const onlyTopIncludedLocations = q.locations.filter(lq => (lq.include && topLocationIds.includes(lq.id)));
  //   if (onlyTopIncludedLocations.length === topLocationIds.length) {
  //     q.locations = q.locations.filter(lq => !topLocationIds.includes(lq.id));
  //     q.locations.push({ id: "*", name: "All locations", include: true });
  //   }
  // });

  // console.log("Query loaded yet");
  let filter = { or: queries };

  // If filter is empty - add top locations
  if (get(filter, "or", []).length === 0 ||
      (get(filter, "or", []).length === 1 && get(filter, "or[0].and", []).length === 0) ||
      (get(filter, "or", []).length === 1 && get(filter, "or[0].and", []).length === 1 && get(filter, "or[0].and[0].or", []).length === 0)) {
    
    filter.or = [{ and: [{ or: []}] }];

    // Add top locations
    props.locationHierarchy.map(l => l.id).forEach(id => {
      filter.or[0].and[0].or.push({ property: "_id", value: id, operator: "eq", options: { includeDescendants: true }});
    });
  }

  // console.log("HomeContainer.onRunQuery - filter", JSON.stringify(filter));
  // console.log("HomeContainer.props", props);
  if (JSON.stringify(filter) !== JSON.stringify(props.previousFilter)) {
    // console.log("HomeContainer.onRunQuery - run location search", JSON.stringify(filter));
    // console.log("HomeContainer.onRunQuery - prev location search", JSON.stringify(props.previousFilter));

    props.queryLocations(filter);
  }
  else {
    // console.log("Skip query");
  }

}

function getInitialView(props) {
  const queryParams = new URLSearchParams(props.history.location.search);
  let views = ["dashboard", "locations"];

  if (props.auth.hasSupportRole) {
    views.push("minmax");
  }

  return views.includes(queryParams.get("v")) ? queryParams.get("v") : "dashboard";
}

function getInitialFilter(props) {
  const queryParams = new URLSearchParams(props.history.location.search);
  return queryParams.get("f");
}

function getInitialLocationId(props) {
  const queryParams = new URLSearchParams(props.history.location.search);
  return queryParams.get("l");
}