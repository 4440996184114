import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { faStar as faEmptyStar } from "@fortawesome/free-regular-svg-icons";
import SearchBox from "../../../components/SearchBox";
import styled from "styled-components";
import { get } from "lodash";

class HomeHeader extends Component {

  constructor(props) {
    console.log("HomeHeader.constructor()", props);
    super(props);
    this.state = {
      hoverStar: false
    };

    this.onHoverStar = this.onHoverStar.bind(this);
  }

  static propTypes = {
    pages: PropTypes.array.isRequired,
    selectedPageId: PropTypes.string,
    selectedLocation: PropTypes.object,
    favoriteLocationId: PropTypes.string,
    onSelectedPageChanged: PropTypes.func.isRequired,
    onToggleFavorite: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
  };

  onHoverStar(hover) {
    this.setState({ hoverStar: hover });
  }

  onPageClick(page) {
    this.props.onPageClick(page);
  }

  render() {
    console.log("HomeHeader.render()", this.props);

    // Get favorite location style
    const selectedLocationId = get(this.props.selectedLocation, "id", "");
    const selectedLocationName = get(this.props.selectedLocation, "name", "");
    const star = this.props.favoriteLocationId === selectedLocationId ? faStar : faEmptyStar;
    const starColor = this.state.hoverStar ? "#aaa" : this.props.favoriteLocationId === selectedLocationId ? "#f5a623" : "#6b7073";

    const pageMenu = this.props.pages.map((page) => (
      <MenuButton
        key={page.id}
        onClick={() => this.props.onSelectedPageChanged(page.id)}
        $isSelected={this.props.selectedPageId === page.id}
        $selectedColor={page.color}
        $color={"#333"}>
        <span style={{ userSelect: "none" }}>{page.name}</span>
      </MenuButton>
    ));

    return (
      <>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            <h1 style={{ display: "inline-block" }}>{selectedLocationName}</h1>
            <StarContainer onClick={(event) => { this.onToggleFavorite(selectedLocationId); event.stopPropagation(); }} onMouseEnter={() => this.onHoverStar(true)} onMouseLeave={() => this.onHoverStar(false)}>
              <FontAwesomeIcon icon={star} fontSize={22} color={starColor} />
            </StarContainer>
            <p>Activity in this building in {moment().subtract(1, "month").format("MMMM")}.</p>
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", gap: "10px", marginBottom: "20px" }}>
          { pageMenu }
        </div>
      </>
    );
  }
}

export default HomeHeader;

const MenuButton = styled.div`
  display: inline-block;
  cursor: pointer;
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: ${(props) => props.$isSelected ? (props.$selectedColor ?? "#1c4d82") : "#fff"};
  border-color: ${(props) =>props.$isSelected ? (props.$selectedColor ?? "#1c4d82") : (props.$color ?? "#1c4d82")};
  border-radius: 6px;
  border-width: 1px;
  border-style: solid;
  padding: 8px 15px;

  span {
    color: ${(props) => props.$isSelected ? "#fff" : (props.$color ?? "#1c4d82")};
    font-weight: 600;
    font-size: 15px;
  }
`;

const StarContainer = styled.div`
  display: inline-flex;
  padding: 0px 0px 0px 10px;
  align-items: start;
  justify-content: start;
  cursor: pointer;
`;